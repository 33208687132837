import React, { useState, useEffect } from 'react';
import { Button, Table, Form, Modal, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { farmerMachineryDetailsAction, formChangedAction } from '../../actions/index';
import { toast } from 'react-toastify';
import EnlargableTextbox from 'components/common/EnlargableTextbox';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FarmersMachinaryDetailsTable = () => {
  const dispatch = useDispatch();
  const [formHasError, setFormError] = useState(false);
  const [rowData, setRowData] = useState([]);

  const columnsArray = [
    'S.No',
    'Equipment Category',
    'Equipment Type',
    'Quantity',
    'Active Status',
    'Action'
  ];

  const [modalShow, setModalShow] = useState(false);
  const [paramsData, setParamsData] = useState({});

  const emptyRow = {
    id: rowData.length + 1,
    encryptedFarmerCode: localStorage.getItem("EncryptedFarmerCode") ? localStorage.getItem("EncryptedFarmerCode") : '',
    encryptedCompanyCode: localStorage.getItem("EncryptedCompanyCode") ? localStorage.getItem("EncryptedCompanyCode") : '',
    encryptedClientCode: localStorage.getItem("EncryptedClientCode") ? localStorage.getItem("EncryptedClientCode") : '',
    machineryCategory: '',
    machineryType: '',
    machineryQty: '',
    activeStatus: '',
    addUser: localStorage.getItem("LoginUserName"),
    modifyUser: localStorage.getItem("LoginUserName")
  }

  const farmerMachineryDetailsReducer = useSelector((state) => state.rootReducer.farmerMachineryDetailsReducer)
  var farmerMachineryDetailsData = farmerMachineryDetailsReducer.farmerMachineryDetails;

  const farmerDetailsErrorReducer = useSelector((state) => state.rootReducer.farmerDetailsErrorReducer)
  const farmerError = farmerDetailsErrorReducer.farmerDetailsError;

  const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
  var formChangedData = formChangedReducer.formChanged;

  useEffect(() => {
    setRowDataValue(farmerMachineryDetailsReducer, farmerMachineryDetailsData);
  }, [farmerMachineryDetailsData, farmerMachineryDetailsReducer]);

  const setRowDataValue = (farmerMachineryDetailsReducer, farmerMachineryDetailsData) => {
    setRowData(farmerMachineryDetailsReducer.farmerMachineryDetails.length > 0 ? farmerMachineryDetailsData : []);
  };

  const validateFarmerMachineryDetailsForm = () => {
    let isValid = true;

    if (farmerMachineryDetailsData && farmerMachineryDetailsData.length > 0) {
      farmerMachineryDetailsData.forEach((row, index) => {
        if (!row.machineryCategory || !row.machineryType || !row.machineryQty) {
          isValid = false;
          setFormError(true);
        }
      });
    }

    if (isValid) {
      setFormError(false);
    }

    return isValid;
  }

  const handleAddRow = () => {

    if (validateFarmerMachineryDetailsForm()) {
      farmerMachineryDetailsData.unshift(emptyRow);
      dispatch(farmerMachineryDetailsAction(farmerMachineryDetailsData));
    }
  };

  const handleFieldChange = (e, idx) => {
    const { name, value } = e.target;
    var farmerMachineryDetails = [...rowData];
    farmerMachineryDetails[idx][name] = value;
    farmerMachineryDetails = Object.keys(rowData).map(key => {
      return rowData[key];
    })
    dispatch(farmerMachineryDetailsAction(farmerMachineryDetails));

    if (farmerMachineryDetails[idx].encryptedFarmerMachineryCode) {
      dispatch(formChangedAction({
        ...formChangedData,
        machineryDetailUpdate: true
      }))
    } else {
      dispatch(formChangedAction({
        ...formChangedData,
        machineryDetailAdd: true
      }))
    }
  }

  const ModalPreview = (encryptedFarmerMachineryCode) => {
    setModalShow(true);
    setParamsData({ encryptedFarmerMachineryCode });
  }

  const deleteFarmerMachineryDetails = () => {
    if (!paramsData)
      return false;

    var objectIndex = farmerMachineryDetailsReducer.farmerMachineryDetails.findIndex(x => x.encryptedFarmerMachineryCode == paramsData.encryptedFarmerMachineryCode);
    farmerMachineryDetailsReducer.farmerMachineryDetails.splice(objectIndex, 1)

    var deleteFarmerMachineryDetailCode = localStorage.getItem("DeleteFarmerMachineryDetailCodes");

    if (paramsData.encryptedFarmerMachineryCode) {
      var deleteFarmerMachineryDetails = deleteFarmerMachineryDetailCode ? deleteFarmerMachineryDetailCode + "," + paramsData.encryptedFarmerMachineryCode : paramsData.encryptedFarmerMachineryCode;
      localStorage.setItem("DeleteFarmerMachineryDetailCodes", deleteFarmerMachineryDetails);
    }

    toast.success("Equipment details deleted successfully", {
      theme: 'colored'
    });

    dispatch(farmerMachineryDetailsAction(farmerMachineryDetailsData));

    dispatch(formChangedAction({
      ...formChangedData,
      machineryDetailDelete: true
    }))

    setModalShow(false);
  }

  return (
    <>
      {modalShow && paramsData &&
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Are you sure, you want to delete this machinery detail?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => setModalShow(false)}>Cancel</Button>
            <Button variant="danger" onClick={() => deleteFarmerMachineryDetails()}>Delete</Button>
          </Modal.Footer>
        </Modal>
      }

      <Card className="h-100 mb-2">
        <FalconCardHeader
          title="Machinery Details"
          titleTag="h6"
          className="py-2"
          light
          endEl={
            <Flex>
              <div >
                <Button
                  variant="primary"
                  size="sm"
                  className="btn-reveal"
                  type="button"
                  onClick={handleAddRow}
                >
                  <i className="fa-solid fa-plus" />
                </Button>
              </div>
            </Flex>
          }
        />
        {
          farmerMachineryDetailsData && farmerMachineryDetailsData.length > 0 &&
          <Card.Body className="position-relative pb-0 p3px tab-page-button-table-card">
            <Form
              noValidate
              validated={formHasError || (farmerError.machineryDetailErr && farmerError.machineryDetailErr.invalidMachineryDetail)}
              className="details-form"
              id="AddFarmersMachinaryTableDetailsForm"
            >
              <Table striped bordered responsive id="TableList" className="no-pb text-nowrap tab-page-table">
                <thead className='custom-bg-200'>
                  <tr>
                    {columnsArray.map((column, index) => (
                      <th className="text-left" key={index}>
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody id="tbody" className="details-form">
                  {rowData.map((farmerMachineryDetailsData, idx) => (
                    <tr key={idx}>
                      <td>
                        {idx + 1}
                      </td>
                      <td key={idx}>
                        <Form.Select
                          type="text"
                          id="txtEquipment"
                          name="machineryCategory"
                          className="form-control"
                          onChange={(e) => handleFieldChange(e, idx)}
                          value={farmerMachineryDetailsData.machineryCategory}
                          required
                        >
                          <option value=''>Select</option>
                          <option value="HIRED">HIRED</option>
                          <option value="OWNED">OWNED</option>
                        </Form.Select>
                      </td>

                      <td key={idx}>
                        <Form.Select
                          type="text"
                          id="txtequipmentType"
                          name="machineryType"
                          value={farmerMachineryDetailsData.machineryType}
                          placeholder="Equipment Type"
                          className="form-control"
                          onChange={(e) => handleFieldChange(e, idx)}
                          required
                        >
                          <option value=''>Select</option>
                          <option value='Tractor'>Tractor</option>
                          <option value='Leveller'>Leveller</option>
                          <option value='Biogas'>Biogas</option>
                          <option value='Vermi Compost'>Vermi Compost</option>
                          <option value='Polyhouse'>Polyhouse</option>
                          <option value='NetHouse'>NetHouse</option>
                          <option value='Solar Light'>Solar Light</option>
                          <option value='Spray Pump'>Spray Pump</option>
                          <option value='Vegetable Crates'>Vegetable Crates</option>
                          <option value='Tillerweeder'>Tillerweeder</option>
                          <option value='Irregation Pump'>Irregation Pump</option>
                          <option value='Weeder'>Weeder</option>
                          <option value='Tiller'>Tiller</option>
                        </Form.Select>
                      </td>

                      <td key={idx}>
                        <EnlargableTextbox
                          id="txtQuantity"
                          name="machineryQty"
                          value={farmerMachineryDetailsData.machineryQty}
                          placeholder="Quantity"
                          onChange={(e) => handleFieldChange(e, idx)}
                          onKeyPress={(e) => {
                            const regex = /[0-9]|\./;
                            const key = String.fromCharCode(e.charCode);
                            if (!regex.test(key)) {
                              e.preventDefault();
                            }
                          }}
                          maxLength={3}
                          required={true}
                        />
                      </td>

                      <td key={idx}>
                        <Form.Select
                          id="txtStatus"
                          name="activeStatus"
                          className="form-control"
                          onChange={(e) => handleFieldChange(e, idx)}
                          value={farmerMachineryDetailsData.activeStatus}
                        >
                          <option value="Active">Active</option>
                          <option value="Suspended">Suspended</option>
                        </Form.Select>
                      </td>
                      <td>
                        <FontAwesomeIcon icon={'trash'} className="fa-2x" onClick={() => { ModalPreview(farmerMachineryDetailsData.encryptedFarmerMachineryCode) }} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Form>
          </Card.Body>
        }
      </Card>
    </>
  )
}

export default FarmersMachinaryDetailsTable