import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TabPage from 'components/common/TabPage';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Spinner, Modal, Button } from 'react-bootstrap';
import $ from 'jquery';
import {
  tabInfoAction,
  formChangedAction,
  demandConsolidationAction,
  demandConsolidationProductsAction
} from 'actions';
import { toast } from 'react-toastify';

const tabArray = ['Add Demand Consolidation'];

const listColumnArray = [
  { accessor: 'sl', Header: 'S. No' },
  { accessor: 'demandNo', Header: 'Demand No.' },
  { accessor: 'demandDate', Header: 'Demand Date' },
  { accessor: 'farmerName', Header: 'Farmer Name' },
  { accessor: 'demandStatus', Header: 'Demand Status' },
  { accessor: 'demandPrintStatus', Header: 'Print' }
];

const Demand = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [perPage, setPerPage] = useState(15);
  const [modalShow, setModalShow] = useState(false);
  const [formHasError, setFormError] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedYear, setSelectedYear] = useState();
  
  // const years = Array.from({ length: 11 }, (_, i) => selectedYear - i);
  const years = Array.from({ length: 11 }, (_, i) => ({
    value: currentYear - i,
    key: String(currentYear - i)
  }));

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value));
    const companyCode = localStorage.getItem('EncryptedCompanyCode');
    fetchDemandConsolidationList(1, perPage, companyCode, e.target.value);
  };

  useEffect(() => {
    $("#btnNew").hide();
		$("#btnSave").show();
  }, []);

  const demandConsolidationReducer = useSelector(
    state => state.rootReducer.demandConsolidationReducer
  );
  var demandConsolidationDetails = demandConsolidationReducer.demandConsolidationDetail;
  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(
    value => value === true
  );
  
  const newDetails = () => {
    if (
      localStorage.getItem('EncryptedCompanyCode') &&
      localStorage.getItem('CompanyName')
    ) {
      $('[data-rr-ui-event-key*="Add Demand Consolidation"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="Add Demand Consolidation"]').trigger('click');
      $('#btnSave').attr('disabled', false);
      dispatch(
        tabInfoAction({ title1: `${localStorage.getItem('CompanyName')}` })
      );
    } else {
      toast.error('Please select company first', {
        theme: 'colored',
        autoClose: 5000
      });
    }
  };

  const proceedToPO = () => {
    if(!demandConsolidationDetails.Products) {
      toast.error('Please select atleast one item to proceed', {
        theme: 'colored',
        autoClose: 5000
      })
      setModalShow(false);
    } else if(demandConsolidationDetails.Products.length > 0) {
      checkSingleVendor();
    } 
  };

  const checkSingleVendor = async () => {
    if(demandConsolidationDetails.Products.length > 0){
      const Products = demandConsolidationDetails.Products.map(row => ({
        ProductCode: row.ProductCode,
        ProductCategoryCode: row.ProductCategoryCode,
        OemCode: row.OemCode,
        OemProductCatalogueCode: row.OemProductCatalogueCode
      }));
  
      let token = localStorage.getItem('Token');
      let response = await axios.post(
        process.env.REACT_APP_API_URL + '/single-vendor-check',
        {ProductDetails : Products},
        {
          headers: { Authorization: `Bearer ${JSON.parse(token).value}` }
        }
      );
      if(response.data.status == 200) {
        if(!response.data.data.vendorExists) {
          toast.error("No vendor is providing all the selected items", {
            theme: 'colored',
            autoClose: 5000
          });
          // $("#btnSave").attr('disabled', true);
        } else {
          $("#btnSave").attr('disabled', false);
          clearDemandConsolidationProductsReducers();
          navigate('/demand-po-order');
        }
      } else {
        $("#btnSave").attr('disabled', false);
      }
    }
  };

  const clearDemandConsolidationProductsReducers = () => {
		dispatch(formChangedAction(undefined));
		dispatch(demandConsolidationProductsAction([]));
	}

    const cancelClick = () => {
      $('#btnExit').attr('isExit', 'false');
      if (isFormChanged) {
          setModalShow(true);
      } else {
          $('[data-rr-ui-event-key*="Demand Consolidation List"]').trigger('click');
      }
    }

    const exitModule = () => {
      $('#btnExit').attr('isExit', 'true');
      if (isFormChanged) {
          setModalShow(true);
      } else {
          window.location.href = '/dashboard';
          dispatch(demandConsolidationAction(undefined));
          dispatch(demandConsolidationProductsAction(undefined));
      }
  }

  const discardChanges = () => {
		$('#btnDiscard').attr('isDiscard', 'true');
		if ($('#btnExit').attr('isExit') == 'true')
			window.location.href = '/dashboard';
		
    dispatch(formChangedAction(undefined));
    dispatch(demandConsolidationAction(undefined));
    dispatch(demandConsolidationProductsAction(undefined));
		setModalShow(false);
	}

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {modalShow &&
              <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5>Do you want to save changes?</h5>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="success" onClick={proceedToPO}>Save</Button>
                  <Button variant="danger" id='btnDiscard' onClick={discardChanges}>Discard</Button>
                </Modal.Footer>
              </Modal>
            }
      
      <TabPage
        tabArray={tabArray}
        module="DemandConsolidation"    
        saveDetails={proceedToPO}
        cancelClick={cancelClick}
        exitModule={exitModule}
        handleYearChange={handleYearChange}
        yearList={years}
        selectedYear={selectedYear}
        newDetails={newDetails}
      />
    </>
  );
};

export default Demand;
