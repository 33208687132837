const initialState = {
  menuDetailsError: {
    menuNameErr: {},
    controlKeyErr: {},
    commandKeyErr: {}
  }
};

const menuDetailsErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'MENUDETAILSERROR':
      if (!action.payload) {
        return initialState
      }
      else {
        return {
          ...state,
          menuDetailsError: action.payload
        }
      }
    default:
      return state;
  }
};

export default menuDetailsErrorReducer;