import React, { useState, useCallback, useMemo, useEffect  } from 'react';
import { Col, Form, Row, Card, Table, Button, Accordion  } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { demandConsolidationAction, demandConsolidationProductsAction, formChangedAction } from 'actions';

const AddDemandConsolidation = ({yearList}) => {
  const today = useMemo(() => new Date().toISOString().split('T')[0], []);

  const dispatch = useDispatch();
 
  const months = [
    { name: 'January', value: '01' },
    { name: 'February', value: '02' },
    { name: 'March', value: '03' },
    { name: 'April', value: '04' },
    { name: 'May', value: '05' },
    { name: 'June', value: '06' },
    { name: 'July', value: '07' },
    { name: 'August', value: '08' },
    { name: 'September', value: '09' },
    { name: 'October', value: '10' },
    { name: 'November', value: '11' },
    { name: 'December', value: '12' }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [productNames, setProductNames] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [selectedSowingYear, setSelectedSowingYear] = useState("");
  const [selectedSowingMonth, setSelectedSowingMonth] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAccordions, setOpenAccordions] = useState([]);

  useEffect(() => {
    addDemandForPO();
  }, [selectedRows]);
  
  const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
  var formChangedData = formChangedReducer.formChanged;

  const demandConsolidationProductsReducer = useSelector((state) => state.rootReducer.demandConsolidationProductsReducer)
	var demandConsolidationProductsDetail = demandConsolidationProductsReducer.demandConsolidationProductsDetail;

  useEffect(() => {
    if(!demandConsolidationProductsDetail) {
      setSelectedSowingMonth();
    }
  }, [demandConsolidationProductsDetail, demandConsolidationProductsAction]);

  const handleSowingYearChange = (event) => {
    dispatch(formChangedAction({
      ...formChangedData,
      demandConsolidationAdd: true,
    }))
    setSelectedSowingYear(event.target.value);
    setSelectedRows([]);
    setProductNames([]);
  };

  const handleSowingMonthChange = (event) => {
    dispatch(formChangedAction({
      ...formChangedData,
      demandConsolidationAdd: true,
    }))
    setSelectedSowingMonth(event.target.value);
    setSelectedRows([]);
    setProductNames([]);
  };
  
 
  // Function to toggle the open state of an accordion
  const toggleAccordion = (index) => {
    if (openAccordions.includes(index)) {
      // If accordion is open, remove it from the openAccordions array
      setOpenAccordions((prevState) => prevState.filter((item) => item !== index));
    } else {
      // If accordion is closed, add it to the openAccordions array
      setOpenAccordions((prevState) => [...prevState, index]);
    }
  };

  // Function to check if an accordion is open based on its index
  const isAccordionOpen = (index) => openAccordions.includes(index);

  const handleCheckboxChange = (productDetail, index) => {
    const checkbox = $(`#singleProdChk${index}`);
    setSelectedRows(prevSelectedRows => {
      const currentIndex = prevSelectedRows.findIndex(row => row.productCode === productDetail.productCode);
      if (currentIndex === -1) {
        checkbox.prop("checked", true);
        return [...prevSelectedRows, productDetail];
      } else {
        checkbox.prop("checked", false);
        return prevSelectedRows.filter((_, i) => i !== currentIndex);
      }
    });
  };

    const addDemandForPO = async () => {
      if(selectedRows.length > 0){
        const Products = selectedRows.map(row => ({
          ProductCode: row.productCode,
          ProductCategoryCode: row.productCategoryCode,
          OemCode: row.oemCode,
          OemProductCatalogueCode: row.oemProductCatalogueCode,
          SowingYear: selectedSowingYear,
          SowingMonth : selectedSowingMonth
        }));
    
        dispatch(demandConsolidationAction({
          Products
        }))
      } else {
        dispatch(demandConsolidationAction(undefined));
      }
    };

    
  // const checkSingleVendor = async () => {
    //   if(selectedRows.length > 0){
    //     const Products = selectedRows.map(row => ({
    //       ProductCode: row.productCode,
    //       ProductCategoryCode: row.productCategoryCode,
    //       OemCode: row.oemCode,
    //       OemProductCatalogueCode: row.oemProductCatalogueCode
    //     }));
    
    //     dispatch(demandConsolidationAction({
    //       Products
    //     }))
    //     let token = localStorage.getItem('Token');
    //     let response = await axios.post(
    //       process.env.REACT_APP_API_URL + '/single-vendor-check',
    //       {ProductDetails : Products},
    //       {
    //         headers: { Authorization: `Bearer ${JSON.parse(token).value}` }
    //       }
    //     );
    //     if(response.data.status == 200) {
    //       if(!response.data.data.vendorExists) {
    //         toast.error("No vendor is providing all the selected items", {
    //           theme: 'colored',
    //           autoClose: 5000
    //         });
    //         // $("#btnSave").attr('disabled', true);
    //       } else {
    //         $("#btnSave").attr('disabled', false);
    //       }
    //     } else {
    //       $("#btnSave").attr('disabled', false);
    //     }
    //   }
    // };

  const fetchApprovedDemandProductNames = async () => {
    dispatch(formChangedAction({
      ...formChangedData,
      demandConsolidationAdd: true,
    }))
    if(!selectedSowingYear) {
      toast.error("Please select sowing year", {
        theme: 'colored',
        autoClose: 5000
      });
      setValidated(true);
    }
    else {
      let token = localStorage.getItem('Token');
  
      const listFilter = {
        SowingMonth : selectedSowingMonth,
        SowingYear: selectedSowingYear
      };
  
      setIsLoading(true);
      let response = await axios.post(
        process.env.REACT_APP_API_URL + '/get-approved-demand-product-Name',
        listFilter,
        {
          headers: { Authorization: `Bearer ${JSON.parse(token).value}` }
        }
      );
  
      if (response.data.status == 200) {
        setIsLoading(false);
        setOpenAccordions([]);
        setProductNames(response.data.data);
        dispatch(demandConsolidationProductsAction(response.data.data));
      } else {
        toast.error('No demands found for the selected criteria!', {
          theme: 'colored',
          autoClose: 5000
        });
        setIsLoading(false);
        setProductNames([]);
        dispatch(demandConsolidationProductsAction(undefined));
      }
    }
  };

  const fetchApprovedDemandProductDetail = async (productCode, oemCode, index) => {
      let token = localStorage.getItem('Token');
      const listFilter = {
        SowingMonth : selectedSowingMonth,
        SowingYear: selectedSowingYear,
        ProductCode: productCode,
        OemCode : oemCode
      };
  
      setIsLoading(true);
      let response = await axios.post(
        process.env.REACT_APP_API_URL + '/get-approved-demand-product-detail',
        listFilter,
        {
          headers: { Authorization: `Bearer ${JSON.parse(token).value}` }
        }
      );
  
      if (response.data.status == 200) {
        setIsLoading(false);
        const productDetails = response.data.data[0];
        setProductDetails(prevProductDetails => {
          const updatedProductDetails = [...prevProductDetails];
          updatedProductDetails[index] = response.data.data[0];
          return updatedProductDetails;
        });

        for (let i = 0; i < demandConsolidationProductsDetail.length; i++) {
          if(demandConsolidationProductsDetail[i].productCode === productDetails.productCode) {
            demandConsolidationProductsDetail[i].productDetails = productDetails
          }
        }

        dispatch(demandConsolidationProductsAction(demandConsolidationProductsDetail));

      } else {
        setIsLoading(false);
        setProductDetails([]);
      }
  };

  return (
    <>
      <Form noValidate validated={validated}>
        <Row>
        <Col md="4">
            <Form.Group
              as={Row}
              className="mb-1"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4">
                Sowing Year <span className="text-danger">*</span>
              </Form.Label>
              <Col sm="8">
                <Form.Select onChange={handleSowingYearChange} disabled={yearList.length == 1} value={selectedSowingYear} required>
                  {yearList.length > 1 &&
                    <option value=''>Select Year</option>
                  }
                  {yearList.map((year, index) => (
                    <option key={index} value={year.value}>{year.key}</option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group
              as={Row}
              className="mb-1"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4">
                Sowing Month
              </Form.Label>
              <Col sm="8">
                <Form.Select onChange={handleSowingMonthChange} disabled={months.length == 1} value={selectedSowingMonth}>
                  {months.length > 1 &&
                    <option value=''>Select Month</option>
                  }
                  {months.map((month, index) => (
                    <option key={index} value={month.value}>{month.name}</option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group
              as={Row}
              className="mb-1"
              controlId="formPlaintextPassword"
            >
              <Col className='col-auto'>
                <Button variant="success" onClick={() => fetchApprovedDemandProductNames()} >Search</Button>
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      {/* <Card className="h-100 mb-2 mt-0 ">
      <Card.Body className="position-relative pb-0 p3px cp-table-card cp-table-card-responsive"> */}
       <div className="accordion" id="accordionExample">
        {demandConsolidationProductsDetail.length > 0 && demandConsolidationProductsDetail.map((product, index) => (
          <div className="accordion-item" key={index} onClick={()=> fetchApprovedDemandProductDetail(product.productCode, product.oemCode, index)}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button className="accordion-button" type="button" 
              // data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}
              onClick={() => toggleAccordion(index)} // Toggle accordion state on button click
              aria-expanded={isAccordionOpen(index)} // Set aria-expanded based on accordion state
              >
                {`${product.productName} (${product.productCategoryName})`}
              </button>
            </h2>
            <div id={`collapse${index}`} 
              className={`accordion-collapse collapse ${isAccordionOpen(index) ? 'show' : ''}`} // Add 'show' class if accordion is open
              // className="accordion-collapse collapse"
              aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
              <div className="accordion-body">
              <Table
                striped
                bordered
                responsive
                id={`TableList${index}`}
                className="no-pb text-nowrap tab-page-table"
              >
                <thead className="custom-bg-200">
                  <tr>
                  <th></th>
                  <th>Brand Name (OEM)</th>
                  <th>Demand Qty</th>
                  <th>Purchased Qty</th>
                  </tr>
                </thead>
                <tbody id={`tbody${index}`} className="details-form">
                {product.productDetails && (
                  <tr>
                  <td key={index}>
                                <Form.Check type="checkbox" className="mb-1">
                                  <Form.Check.Input
                                    type="checkbox"
                                    id={`singleProdChk${index}`}
                                    name="singleChkBox"
                                    style={{ width: '20px', height: '20px' }}
                                    onChange={() => handleCheckboxChange(product.productDetails, index)}
                                    // checked={selectedRows.some(item => deepEqual(item, productDetails[0]))}
                                  />
                                </Form.Check>
                              </td>
                    <td>{product.productDetails.oemName}</td>
                    <td>{product.productDetails.demandQty}</td>
                    <td>{product.productDetails.purchasedQty}</td>
                  </tr>
                )}
                  {/* // <tr>
                  //   <td colSpan={4} className='text-sm-start'>Product No found</td>
                  // </tr> */}
                </tbody>
                </Table>
                </div>
            </div>
          </div>
        ))}
      </div>
      {/* </Card.Body>
      </Card> */}
    </>
  );
}

export default AddDemandConsolidation