import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Dashboard = () => {

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={6} xxl={3}>
          <h4>Welcome Admin!</h4>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;