const initialState = {
	demandDistributionProductDetail: []
};

const demandDistributionProductDetailReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'DEMANDDISTRIBUTIONPRODUCTDETAIL':
			if (!action.payload) {
				return initialState
			}
			else {
				return {
					...state,
					demandDistributionProductDetail: action.payload
				}
			}
		default:
			return state;
	}
};

export default demandDistributionProductDetailReducer;