import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TabPage from 'components/common/TabPage';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner, Modal, Button } from 'react-bootstrap';
import $ from 'jquery';
import axios from 'axios';
import {
	tabInfoAction,
	formChangedAction,
	demandConsolidationAction,
	demandDistributionProductAction,
	demandDistributionAddErrorAction,
	demandDistributionProductDetailAction
} from 'actions';
import { toast } from 'react-toastify';

const tabArray = ['Add Demand Distribution'];

const listColumnArray = [
	{ accessor: 'sl', Header: 'S. No' },
	{ accessor: 'demandNo', Header: 'Demand No.' },
	{ accessor: 'demandDate', Header: 'Demand Date' },
	{ accessor: 'farmerName', Header: 'Farmer Name' },
	{ accessor: 'demandStatus', Header: 'Demand Status' },
	{ accessor: 'demandPrintStatus', Header: 'Print' }
];

const DemandDistribution = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [formError, setFormError] = useState(false);

	useEffect(() => {
		$("#btnNew").hide();
		$("#btnSave").show();
	}, []);

	const formChangedReducer = useSelector(
		state => state.rootReducer.formChangedReducer
	);
	var formChangedData = formChangedReducer.formChanged;

	let isFormChanged = Object.values(formChangedData).some(
		value => value === true
	);

	const demandDistributionProduct = useSelector((state) => state.rootReducer.demandDistributionProductReducer.demandDistributionProduct);
	const demandDistributionAddError = useSelector((state) => state.rootReducer.demandDistributionAddErrorReducer.demandDistributionAddError);
	const demandDistributionFilter = useSelector((state) => state.rootReducer.demandDistributionReducer.demandDistributionDetail);
	const newDetails = () => {
		if (
			localStorage.getItem('EncryptedCompanyCode') &&
			localStorage.getItem('CompanyName')
		) {
			$('[data-rr-ui-event-key*="Add Demand Distribute"]').attr('disabled', false);
			$('[data-rr-ui-event-key*="Add Demand Distribute"]').trigger('click');
			$('#btnSave').attr('disabled', false);
			dispatch(
				tabInfoAction({ title1: `${localStorage.getItem('CompanyName')}` })
			);
		} else {
			toast.error('Please select company first', {
				theme: 'colored',
				autoClose: 5000
			});
		}
	};

	const cancelClick = () => {
		$('#btnExit').attr('isExit', 'false');
		if (isFormChanged) {
			setModalShow(true);
		} else {
			$('[data-rr-ui-event-key*="Demand Distribution List"]').trigger('click');
		}
	}

	const exitModule = () => {
		$('#btnExit').attr('isExit', 'true');
		if (isFormChanged) {
			setModalShow(true);
		} else {
			window.location.href = '/dashboard';
			dispatch(demandConsolidationAction(undefined));
			dispatch(demandDistributionProductAction(undefined));
		}
	}

	const discardChanges = () => {
		$('#btnDiscard').attr('isDiscard', 'true');
		if ($('#btnExit').attr('isExit') == 'true')
			window.location.href = '/dashboard';
		dispatch(formChangedAction(undefined));
		dispatch(demandConsolidationAction(undefined));
		dispatch(demandDistributionProductAction(undefined));
		setModalShow(false);
	}

	const demandValidation = () => {
		let isValid = true;
	
		if (demandDistributionProduct.length > 0) {
			demandDistributionProduct.forEach((demandDistribution, index) => {
				// if (!demandDistribution.distributingQty) {
				// 	demandDistributionAddError[index] = {
				// 		...demandDistributionAddError[index],
				// 		distributingQtyErr: {
				// 			empty: 'Enter Distributing Qty'
				// 		}
				// 	};
				// 	isValid = false;
				// 	setFormError(true);
				// } else 
				if (demandDistribution.distributingQty && (isNaN(parseFloat(demandDistribution.distributingQty)) || parseFloat(demandDistribution.distributingQty) == 0)) {
					demandDistributionAddError[index] = {
						...demandDistributionAddError[index],
						distributingQtyErr: {
							empty: 'Please enter a valid Distributing Qty'
						}
					};
					isValid = false;
					setFormError(true);
				} else {
					demandDistributionAddError[index] = {
						...demandDistributionAddError[index],
						distributingQtyErr: undefined
					};
				}
			});
		}
	
		if (!isValid) {
			dispatch(demandDistributionAddErrorAction(demandDistributionAddError));
		}
	
		return isValid;
	};	

	const addDemandDistributionDetails = () => {
		if(demandValidation()) {
			const requestData = demandDistributionProduct.map(distribution => {
				return {
					ClientCode:distribution.clientCode,
					CompanyCode: distribution.companyCode,
					DemandNo: distribution.demandNo,
					DemandDetailId: distribution.demandDetailId,
					ProductCategoryCode: distribution.productCategoryCode,
					ProductCode: distribution.productCode,
					VendorCode:  distribution.vendorCode,
					SowingYear: distribution.sowingYear,
					SowingMonth: distribution.sowingMonth,
					OemCode: distribution.oemCode,
					OemProductCatalogueCode: distribution.oemProductCatalogueCode,
					VendorProductCatalogueCode: distribution.vendorProductCatalogueCode,
					distributingQty : distribution.distributingQty,
					DistributedQty: distribution.demandDistributedQty,
					addUser: localStorage.getItem("LoginUserName")
				};				
			}).filter(item =>  item.distributingQty !== undefined && item.distributingQty !== null && parseFloat(item.distributingQty) !== 0 && !isNaN(parseFloat(item.distributingQty)));
			const keys = ['addUser']
			for (const key of Object.keys(requestData).filter((key) => keys.includes(key))) {
				requestData[key] = requestData[key] ? requestData[key].toUpperCase() : "";
			}
			if(requestData.length > 0){
				setIsLoading(true);
				axios.post(process.env.REACT_APP_API_URL + '/add-demand-distribution-detail', {'DemandDistributionDetails' : requestData}, {
					headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('Token')).value}` }
				}).then(res => {
					if (res.data.status == 200) {
						setIsLoading(false)
						fetchFarmerDemandDetail();
						const demandDistributionProducts = demandDistributionProduct.map(distribution => ({
							...distribution,
							distributingQty: ""
						}));

						dispatch(demandDistributionProductAction(demandDistributionProducts))
						dispatch(formChangedAction(undefined));
						$("#btnSave").attr('disabled', true);
						toast.success(res.data.message, {
							theme: 'colored',
							autoClose: 10000
						})
						
					} else {
						setIsLoading(false)
						toast.error(res.data.message, {
							theme: 'colored',
							autoClose: 10000
						});
					}
				})
			}
		}
	}

	const fetchFarmerDemandDetail = async () => {
			let token = localStorage.getItem('Token');

			const listFilter = {
				FarmerCode: demandDistributionFilter.farmerCode,
				SowingMonth: demandDistributionFilter.sowingMonth,
				SowingYear: String(demandDistributionFilter.sowingYear)
			};

			setIsLoading(true);
			let response = await axios.post(
				process.env.REACT_APP_API_URL + '/get-farmer-demand-details',
				listFilter,
				{
					headers: { Authorization: `Bearer ${JSON.parse(token).value}` }
				}
			);

			if (response.data.status == 200) {
				setIsLoading(false);
				dispatch(demandDistributionProductAction(response.data.data));
				dispatch(demandDistributionProductDetailAction(undefined));
				// setOpenAccordions([]);
				// setProductNames(response.data.data);
			} else {
				toast.error('No demands found for the selected criteria!', {
					theme: 'colored',
					autoClose: 5000
				});
				dispatch(demandDistributionProductAction(undefined));
				dispatch(demandDistributionProductDetailAction(undefined));
				setIsLoading(false);
			}
	};

	return (
		<>
			{isLoading ? (
				<Spinner
					className="position-absolute start-50 loader-color"
					animation="border"
				/>
			) : null}

			{modalShow &&
				<Modal
					show={modalShow}
					onHide={() => setModalShow(false)}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">Confirmation</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h5>Do you want to save changes?</h5>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="success" onClick={addDemandDistributionDetails}>Save</Button>
						<Button variant="danger" id='btnDiscard' onClick={discardChanges}>Discard</Button>
					</Modal.Footer>
				</Modal>
			}

			<TabPage
				// listData={listData}
				listColumnArray={listColumnArray}
				tabArray={tabArray}
				module="DemandDistribution"
				// tableFilterOptions={companyList}
				// tableFilterName={'Company'}
				saveDetails={addDemandDistributionDetails}
				cancelClick={cancelClick}
				exitModule={exitModule}
				// supportingMethod1={handleFieldChange}
				newDetails={newDetails}
			/>
		</>
	);
};

export default DemandDistribution;
