import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import is from 'is_js';
import { toast, ToastContainer } from 'react-toastify';
import 'assets/css/custom.css';
import 'assets/css/responsive.css';

import MainLayout from './MainLayout';
import HomeLayout from './HomeLayout';
import Home from 'components/Home';
import Error500 from 'components/errors/Error500';

import CardLogin from 'components/authentication/card/Login';
import CardForgetPassword from 'components/authentication/card/ForgetPassword';
import CardConfirmMail from 'components/authentication/card/ConfirmMail';
import CardPasswordReset from 'components/authentication/card/PasswordReset';
import Logout from 'components/authentication/card/Logout';

import Spinners from 'components/doc-components/Spinners';
import Dashboard from 'components/dashboards/default/dashboard';
import Clients from 'components/Clients/clients';
import CompanyMaster from 'components/Company/CompanyMaster';
import User from 'components/User/User';
import Product from 'components/Product/Product';
import Farmers from 'components/Farmers/Farmers';
import CollectionCentre from 'components/CollectionCentre/CollectionCentre';
import DistributionCentre from 'components/DistributionCentre/DistributionCentre';
import ProductLine from 'components/ProductLine/ProductLine';
import MenuDetails from 'components/MenuDetails/MenuDetails';
import ProductMaster from 'components/ProductMaster/ProductMaster';
import OemMaster from 'components/OemMaster/OemMaster';
import VendorMaster from 'components/VendorMaster/VendorMaster';
import PurchaseOrder from 'components/PurchaseOrder/PurchaseOrder';
import MaterialReceipt from 'components/MaterialReceipt/MaterialReceipt';
import InventoryDetail from 'components/Inventory/InventoryDetail';
import Invoice from 'components/common/Invoice';
import CropPurchaseV1 from 'components/CropPurchaseV1/CropPurchaseV1';
import CropPurchase from 'components/CropPurchaseV2/CropPurchase';
import CropPurchaseReport from 'components/CropPurchaseV2/CropPurchaseReport';
import ClientUser from 'components/ClientUsers/ClientUsers';
import VendorInvoice from 'components/VendorInvoiceEntry/VendorInvoice';
import VendorInvoiceEntryReport from 'components/VendorInvoiceEntry/VendorInvoiceEntryReport';
import Payment from 'components/Payment/Payment';
import Demand from 'components/Demand/Demand';
import DemandCollectionReport from 'components/Demand/DemandCollectionReport';
import DemandConsolidation from 'components/DemandConsolidation/DemandConsolidation';
import DemandPurchaseOrder from 'components/DemandConsolidation/DemandPurchaseOrder';
import DemandDistribution from 'components/DemandDistribution/DemandDistribution';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/error" element={<Error500 />} />
        </Route>
        <Route path="/login" element={<CardLogin />} />
        <Route path="/forgot-password" element={<CardForgetPassword />} />
        <Route path="/confirm-mail" element={<CardConfirmMail />} />
        <Route path="/reset-password/:id" element={<CardPasswordReset />} />
        <Route path="/logout" element={<Logout />} />

        <Route path="/spinners" element={<Spinners />} />

        <Route path="/crop-purchase-receipt/:poNo" element={<Invoice />} />
        <Route path="/material-receipt/:id" element={<Invoice />} />
        <Route path="/purchase-order-receipt/:poNo" element={<Invoice />} />
        <Route path="/crop-purchase-report" element={<CropPurchaseReport />} />
        <Route path="/vendor-invoice-entry/:invoiceHeaderCode" element={<VendorInvoiceEntryReport />} />
        <Route path="/demand-collection/:demandNo" element={<DemandCollectionReport />} />

        <Route element={<MainLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/clients" element={<Clients />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/companies" element={<CompanyMaster />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/users" element={<User />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/products" element={<Product />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/farmers" element={<Farmers />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/collection-centres" element={<CollectionCentre />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/distribution-centres" element={<DistributionCentre />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/product-lines" element={<ProductLine />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/add-menu" element={<MenuDetails />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/product" element={<ProductMaster />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/oem" element={<OemMaster />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/vendor" element={<VendorMaster />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/purchase-order" element={<PurchaseOrder />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/material-receipt" element={<MaterialReceipt />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/inventory-dashboard" element={<InventoryDetail />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/crop-purchase-v1" element={<CropPurchaseV1 />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/crop-purchase" element={<CropPurchase />} />
        </Route>  
        <Route element={<MainLayout />}>
          <Route path="/client-users" element={<ClientUser />} />
        </Route>   
        <Route element={<MainLayout />}>
          <Route path="/vendor-invoice-entry" element={<VendorInvoice/>} />
        </Route>     
        <Route element={<MainLayout />}>
          <Route path="/Payments" element={<Payment/>} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/demand-collection" element={<Demand />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/demand-consolidation" element={<DemandConsolidation />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/demand-po-order" element={<DemandPurchaseOrder />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/demand-distribution" element={<DemandDistribution />} />
        </Route>
      </Routes>
      <ToastContainer icon={false} position={toast.POSITION.TOP_RIGHT} />
    </>
  );
};

export default Layout;