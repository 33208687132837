export const loginAction = (payload) => {
  return {
    type: 'LOGIN',
    payload
  };
};

export const resetPasswordAction = (payload) => {
  return {
    type: 'RESETPASSWORD',
    payload
  };
};

export const clientContactDetailsAction = (payload) => {
  return {
    type: 'CLIENTCONTACTDETAILSLIST',
    payload
  };
};

export const updateClientContactDetailsAction = (payload) => {
  return {
    type: 'UPDATECLIENTCONTACTDETAILS',
    payload
  };
};

export const transactionDetailsAction = (payload) => {
  return {
    type: 'TRANSACTIONLIST',
    payload
  };
};

export const clientDetailsAction = (payload) => {
  return {
    type: 'CLIENTDETAILS',
    payload
  };
};

export const clientDetailsErrorAction = (payload) => {
  return {
    type: 'CLIENTDETAILSERROR',
    payload
  };
};

export const contactDetailChangedAction = (payload) => {
  return {
    type: 'CONTACTDETAILCHANGED',
    payload
  };
};

export const transactionDetailChangedAction = (payload) => {
  return {
    type: 'TRANSACTIONDETAILCHANGED',
    payload
  };
};

export const companyDetailsAction = (payload) => {
  return {
    type: 'COMPANYDETAILS',
    payload
  };
};

export const clientDataAction = (payload) => {
  return {
    type: 'CLIENTDATA',
    payload
  };
};

export const commonContactDetailsAction = (payload) => {
  return {
    type: 'COMMONCONTACTDETAILS',
    payload
  };
};

export const commonContactDetailsListAction = (payload) => {
  return {
    type: 'COMMONCONTACTDETAILSLIST',
    payload
  };
};

export const clientContactListAction = (payload) => {
  return {
    type: 'CLIENTCONTACTLIST',
    payload
  };
};

export const companyDetailsErrorAction = (payload) => {
  return {
    type: 'COMPANYDETAILSERROR',
    payload
  };
};

export const commonContactDetailChangedAction = (payload) => {
  return {
    type: 'COMMONCONTACTDETAILCHANGED',
    payload
  };
};

export const userDetailsAction = (payload) => {
  return {
    type: 'USERDETAILS',
    payload
  };
};

export const userDetailsErrorAction = (payload) => {
  return {
    type: 'USERDETAILSERROR',
    payload
  };
};

export const productDetailsAction = (payload) => {
  return {
    type: 'PRODUCTDETAILS',
    payload
  };
};

export const productDetailsErrorAction = (payload) => {
  return {
    type: 'PRODUCTDETAILSERROR',
    payload
  };
};

export const selectedProductsAction = (payload) => {
  return {
    type: 'SELECTEDPRODUCTS',
    payload
  };
};

export const farmerDetailsAction = (payload) => {
  return {
    type: 'FARMERDETAILS',
    payload
  };
};

export const farmerDetailsErrorAction = (payload) => {
  return {
    type: 'FARMERDETAILSERROR',
    payload
  };
};

export const farmerFamilyDetailsAction = (payload) => {
  return {
    type: 'FARMERFAMILYDETAILS',
    payload
  };
};

export const bankDetailsAction = (payload) => {
  return {
    type: 'BANKDETAILS',
    payload
  };
};

export const bankDetailsListAction = (payload) => {
  return {
    type: 'BANKDETAILSLIST',
    payload
  };
};

export const farmerCardDetailsAction = (payload) => {
  return {
    type: 'FARMERCARDDETAILS',
    payload
  };
};

export const farmerLiveStockCattleDetailsAction = (payload) => {
  return {
    type: 'FARMERLIVESTOCKCATTLEDETAILS',
    payload
  };
};

export const farmerMachineryDetailsAction = (payload) => {
  return {
    type: 'FARMERMACHINERYDETAILS',
    payload
  };
};

export const farmerIrrigationDetailsAction = (payload) => {
  return {
    type: 'FARMERIRRIGATIONDETAILS',
    payload
  };
};

export const farmerLandDetailsAction = (payload) => {
  return {
    type: 'FARMERLANDDETAILS',
    payload
  };
};

export const figMasterListAction = (payload) => {
  return {
    type: 'FIGMASTERLIST',
    payload
  };
};

export const distributionCentreListAction = (payload) => {
  return {
    type: 'DISTRIBUTIONCENTRELIST',
    payload
  };
};

export const farmerDocumentDetailsAction = (payload) => {
  return {
    type: 'FARMERDOCUMENTDETAILS',
    payload
  };
};

export const formChangedAction = (payload) => {
  return {
    type: 'FORMCHANGED',
    payload
  };
};

export const distributionCentreDetailsAction = (payload) => {
  return {
    type: 'DISTRIBUTIONCENTREDETAILS',
    payload
  };
};

export const distributionCentreDetailsErrorAction = (payload) => {
  return {
    type: 'DISTRIBUTIONCENTREDETAILSERROR',
    payload
  };
};

export const commonContactDetailsErrorAction = (payload) => {
  return {
    type: 'COMMONCONTACTDETAILSERROR',
    payload
  };
};

export const tabInfoAction = (payload) => {
  return {
    type: 'TABINFO',
    payload
  };
};

export const collectionCentreDetailsAction = (payload) => {
  return {
    type: 'COLLECTIONCENTREDETAILS',
    payload
  };
};

export const figDetailsAction = (payload) => {
  return {
    type: 'FIGDETAILS',
    payload
  };
};

export const collectionCentreDetailsErrorAction = (payload) => {
  return {
    type: 'COLLECTIONCENTREDETAILSERROR',
    payload
  };
};

export const productLineDetailsAction = (payload) => {
  return {
    type: 'PRODUCTLINEDETAILS',
    payload
  };
};

export const productLineDetailsErrorAction = (payload) => {
  return {
    type: 'PRODUCTLINEDETAILSERROR',
    payload
  };
};

export const productCategoryDetailAction = (payload) => {
  return {
    type: 'PRODUCTCATEGORYDETAILS',
    payload
  };
};

export const menuDetailAction = (payload) => {
  return {
    type: 'MENUDETAILS',
    payload
  };
};

export const menuDetailsErrorAction = (payload) => {
  return {
    type: 'MENUDETAILSERROR',
    payload
  };
};

export const treeViewAction = (payload) => {
  return {
    type: 'TREEVIEWDETAILS',
    payload
  };
};

export const shortcutKeyCombinationAction = (payload) => {
  return {
    type: 'SHORTCUTKEYLIST',
    payload
  };
};

export const productMasterDetailsAction = (payload) => {
  return {
    type: 'PRODUCTMASTERDETAILS',
    payload
  };
};

export const productVarietyDetailsAction = (payload) => {
  return {
    type: 'PRODUCTVARIETYDETAILS',
    payload
  };
};

export const productMasterDetailsErrorAction = (payload) => {
  return {
    type: 'PRODUCTMASTERDETAILSERROR',
    payload
  };
};

export const oemMasterDetailsAction = (payload) => {
  return {
    type: 'OEMMASTERDETAILS',
    payload
  };
};

export const oemMasterDetailsErrAction = (payload) => {
  return {
    type: 'OEMMASTERDETAILSERROR',
    payload
  };
};

export const oemProductDetailsAction = (payload) => {
  return {
    type: 'OEMPRODUCTDETAILS',
    payload
  };
};

export const vendorMasterDetailsAction = (payload) => {
  return {
    type: 'VENDORMASTERDETAILS',
    payload
  };
};

export const vendorProductCatalogueDetailsAction = (payload) => {
  return {
    type: 'VENDORPRODUCTCATALOGUEDETAILS',
    payload
  };
};

export const vendorMasterDetailsErrAction = (payload) => {
  return {
    type: 'VENDORMASTERDETAILSERROR',
    payload
  };
};

export const vendorMasterDetailsListAction = (payload) => {
  return {
    type: 'VENDORMASTERLISTDETAILS',
    payload
  };
};

export const purchaseOrderDetailsAction = (payload) => {
  return {
    type: 'PURCHASEORDERDETAILS',
    payload
  };
};

export const purchaseOrderProductDetailsAction = (payload) => {
  return {
    type: 'PURCHASEORDERPRODUCTDETAILS',
    payload
  };
};

export const purchaseOrderTermDetailsAction = (payload) => {
  return {
    type: 'PURCHASEORDERTERMDETAILS',
    payload
  };
};

export const purchaseOrderDetailsErrAction = (payload) => {
  return {
    type: 'PURCHASEORDERDETAILSERROR',
    payload
  };
};

export const materialReceiptHeaderDetailsAction = (payload) => {
  return {
    type: 'MATERIALRECEIPTHEADERDETAILS',
    payload
  };
};

export const materialReceiptDetailsAction = (payload) => {
  return {
    type: 'MATERIALRECEIPTDETAILS',
    payload
  };
};

export const materialReceiptErrorAction = (payload) => {
  return {
    type: 'MATERIALRECEIPTERROR',
    payload
  };
};

export const vendorInvoiceEntryHeaderDetailsAction = (payload) => {
  return {
    type: 'VENDORINVOICEENTRYHEADERDETAILS',
    payload
  };
};

export const vendorInvoiceEntryDetailsAction = (payload) => {
  return {
    type: 'VENDORINVOICEENTRYDETAILS',
    payload
  };
};

export const vendorInvoiceEntryErrorAction = (payload) => {
  return {
    type: 'VENDORINVOICEENTRYERROR',
    payload
  };
};

export const paymentDetailsAction = (payload) => {
  return {
    type: 'PAYMENTDETAIL',
    payload
  };
};

export const paymentHeaderAction = (payload) => {
  return {
    type: 'PAYMENTHEADER',
    payload
  };
};

export const paymentErrorAction = (payload) => {
  return {
    type: 'PAYMENTERROR',
    payload
  };
};

export const demandHeaderAction = (payload) => {
  return {
    type: 'DEMANDHEADER',
    payload
  };
};

export const productCatalogueDetailsAction = (payload) => {
  return {
    type: 'PRODUCTCATALOGUEDETAILS',
    payload
  };
};

export const demandProductDetailsAction = (payload) => {
  return {
    type: 'DEMANDPRODUCTDETAILS',
    payload
  };
};

export const demandHeaderDetailsErrAction = (payload) => {
  return {
    type: 'DEMANDHEADERDETAILSERROR',
    payload
  };
};

export const demandConsolidationProductsAction = (payload) => {
  return {
    type: 'DEMANDCONSOLIDATIONPRODUCTS',
    payload
  };
};

export const demandConsolidationAction = (payload) => {
  return {
    type: 'DEMANDCONSOLIDATION',
    payload
  };
};

export const demandDistributionAction = (payload) => {
  return {
    type: 'DEMANDDISTRIBUTION',
    payload
  };
};

export const demandDistributionErrAction = (payload) => {
  return {
    type: 'DEMANDDISTRIBUTIONERROR',
    payload
  };
};

export const demandDistributionProductAction = (payload) => {
  return {
    type: 'DEMANDDISTRIBUTIONPRODUCT',
    payload
  };
};

export const demandDistributionProductDetailAction = (payload) => {
  return {
    type: 'DEMANDDISTRIBUTIONPRODUCTDETAIL',
    payload
  };
};

export const demandDistributionAddErrorAction = (payload) => {
  return {
    type: 'DEMANDDISTRIBUTIONADDERROR',
    payload
  };
};