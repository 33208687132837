import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row, Button, Modal, Table, Card } from 'react-bootstrap';
import axios from 'axios';
import FalconCardBody from 'components/common/FalconCardBody';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { paymentDetailsAction, paymentHeaderAction } from 'actions';
import { handleNumericInputKeyPress } from "./../../helpers/utils.js"
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';

const AddPaymentDetails = () => {
  const [companyList, setCompanyList] = useState([]);
  const [companyMasterList, setCompanyMasterList] = useState([]);
  const [vendorAndMasterDetail, setVendorAndMasterDetail] = useState([]);
  const [vendorAndFarmerList, setVendorAndFarmerList] = useState();
  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [unitList, setUnitList] = useState([])

  const dispatch = useDispatch();

  let paymentDetailsReducer = useSelector((state) => state.rootReducer.paymentDetailReducer)
  let paymentDetails = paymentDetailsReducer.paymentDetails;

  const paymentHeaderDetailsReducer = useSelector((state) => state.rootReducer.paymentHeaderReducer)
  var paymentHeaderDetails = paymentHeaderDetailsReducer.paymentHeaderDetail;

  const resetInvoiceEntryHeaderDetails = () => {
    dispatch(paymentHeaderAction({
      "name": "",
      "address": "",
      "pinCode": "",
      "state": "",
      "country": "",
      "invoiceNo": "",
      "invoiceDate": "",
      "invoiceAmount": "",
      "gstTotalAmt": 0,
      "invoicePaidAmount": 0,
      "balanceAmount": 0
    }))
  }

  useEffect(() => {
    getCompany()
    getUnitList()
  }, []);

  const handleFieldChange = e => {
    if (e.target.name === 'companyCode' && e.target.value) {
      var companyDetail = companyMasterList.find(company => company.encryptedCompanyCode == e.target.value);
      getVendorAndFarmerList(companyDetail.encryptedCompanyCode)
      localStorage.setItem("EncryptedCompanyCode", companyDetail.encryptedCompanyCode)
      dispatch(paymentDetailsAction([]))
      resetInvoiceEntryHeaderDetails()
      setInvoiceData([]);
      setInvoiceList([]);
      setVendorAndMasterDetail([]);
    }
    else if (e.target.name === 'invoicePaidAmount' && e.target.value) {
      if (paymentHeaderDetails.invoiceAmount == parseFloat(e.target.value)) {
        let updatedPaymentDetails = [...paymentDetails];
        for (let i = 0; i < updatedPaymentDetails.length; i++) {
          updatedPaymentDetails[i] = {
            ...updatedPaymentDetails[i],
            paidAmount: updatedPaymentDetails[i].productGrandAmt,
            balanceAmount: 0
          };
        }
        dispatch(paymentDetailsAction(updatedPaymentDetails));
      }
      let balanceAmount = paymentHeaderDetails.invoiceAmount - e.target.value
      dispatch(paymentHeaderAction({
        ...paymentHeaderDetails,
        invoicePaidAmount: e.target.value,
        balanceAmount: balanceAmount,
      }))

      if (e.target.value > 0) {
        $('#btnSave').attr('disabled', false);
      }
      else {
        $("#btnSave").attr('disabled', true);
      }
    }
    else if (e.target.name === 'invoicePaidAmount') {
      let updatedPaymentDetails = [...paymentDetails];
      for (let i = 0; i < updatedPaymentDetails.length; i++) {
        updatedPaymentDetails[i] = {
          ...updatedPaymentDetails[i],
          paidAmount: "",
        };
      }
      dispatch(paymentDetailsAction(updatedPaymentDetails));
      dispatch(paymentHeaderAction({
        ...paymentHeaderDetails,
        invoicePaidAmount: "",
        balanceAmount: paymentHeaderDetails.invoiceAmount,
      }))

      if (e.target.value > 0) {
        $('#btnSave').attr('disabled', false);
      }
      else {
        $("#btnSave").attr('disabled', true);
      }
    }
    else {
      dispatch(paymentHeaderAction({
        ...paymentHeaderDetails,
        [e.target.name]: e.target.value
      }))
      setVendorAndFarmerList([])
      setVendorAndMasterDetail([])
      setInvoiceData([])
      localStorage.removeItem("EncryptedCompanyCode")
      resetInvoiceEntryHeaderDetails()
      dispatch(paymentDetailsAction([]))
      setInvoiceList([]);
    }
  }

  const handleVendorAndFarmerDetail = async (code, name) => {
    var companyDetail = vendorAndFarmerList.find(data => data.code == code && data.name == name);
    dispatch(paymentHeaderAction({
      ...paymentHeaderDetails,
      code: companyDetail.code,
      name: companyDetail.name,
      address: companyDetail.address,
      pinCode: companyDetail.pinCode,
      state: companyDetail.state,
      country: companyDetail.country,
      invoiceNo: "",
      invoiceDate: "",
      invoiceAmount: "",
      invoicePaidAmount: "",
      gstTotalAmt: "",
      invoiceGrandAmt: "",
      invoiceStatus: "",
      balanceAmount:""
    }))
    setInvoiceList([]);
    dispatch(paymentDetailsAction([]))
    fetchVendorInvoiceEntryHeaderList(1, perPage, paymentHeaderDetails.encryptedCompanyCode, code);
  }

  const handleInvoiceDetail = async (invoiceNo) => {
    await getInvoiceDetailList(invoiceNo)
  }

  const getCompany = async () => {
    let companyData = [];
    const companyRequest = {
      EncryptedClientCode: localStorage.getItem("EncryptedClientCode")
    }
    let companyResponse = await axios.post(process.env.REACT_APP_API_URL + '/get-client-companies', companyRequest, {
      headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('Token')).value}` }
    });
    if (companyResponse.data.status == 200) {
      if (companyResponse.data && companyResponse.data.data.length > 0) {
        setCompanyMasterList(companyResponse.data.data);
        if (localStorage.getItem('CompanyCode')) {
          var companyDetails = companyResponse.data.data.find(company => company.companyCode == localStorage.getItem('CompanyCode'));
          companyData.push({
            key: companyDetails.companyName,
            value: companyDetails.encryptedCompanyCode,
            label: companyDetails.companyName
          })
          setCompanyList(companyData);
        }
        else {
          companyResponse.data.data.forEach(company => {
            companyData.push({
              key: company.companyName,
              value: company.encryptedCompanyCode,
              label: company.companyName
            })
          })
          setCompanyList(companyData)
        }
      }
    } else {
      toast.error(companyResponse.data.message, {
        theme: 'colored',
        autoClose: 10000
      });
      setCompanyList([])
    }
  }

  const getVendorAndFarmerList = async (encryptedCompanyCode, searchText) => {
    const request = {
      EncryptedCompanyCode: encryptedCompanyCode,
      searchText: searchText
    }
    let response = await axios.post(process.env.REACT_APP_API_URL + '/get-vendor-and-farmer-list', request, {
      headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('Token')).value}` }
    });
    if (response.data.status == 200) {
      if (response.data && response.data.data.length > 0) {
        setVendorAndFarmerList(response.data.data);
      } else {
        setVendorAndFarmerList([])
      }
    }
    else{
      toast.error(response.data.message, {
        theme: 'colored',
        autoClose: 10000
      });
    }
  }

  const companyValidation = () => {
    if(!localStorage.getItem("EncryptedCompanyCode")){
      toast.error("Please select company first", {
        theme: 'colored',
        autoClose: 5000
    });
    }
  }

  const vendorValidation = () => {
    if(!paymentHeaderDetails.code || paymentHeaderDetails.code == ""){
      toast.error("Please select vendor first", {
        theme: 'colored',
        autoClose: 5000
    });
    }
  }

  const handleVendorAndFarmerOnChange = (e) => {
    if (e.target.value) {
      const searchText = e.target.value;
      const regex = new RegExp(searchText, 'i');
      const filteredList = vendorAndFarmerList && vendorAndFarmerList.filter(data => regex.test(data.name));
      setVendorAndMasterDetail(filteredList);
    }
    else {
      setVendorAndMasterDetail([]);
    }
  }

  const handleInvoiceOnChange = (e) => {
    if (e.target.value) {
      const searchText = e.target.value;
      const regex = new RegExp(searchText, 'i');
      const filteredList = invoiceList && invoiceList.filter(data => regex.test(data.invoiceNo));
      setInvoiceData(filteredList);
    }
    else {
      setInvoiceData(invoiceList);
    }
  }

  const fetchVendorInvoiceEntryHeaderList = async (page, size = perPage, encryptedCompanyCode, code) => {
    let token = localStorage.getItem('Token');

    const listFilter = {
      pageNumber: page,
      pageSize: size,
      EncryptedCompanyCode: localStorage.getItem("EncryptedCompanyCode"),
      code: code
    }

    let response = await axios.post(process.env.REACT_APP_API_URL + '/get-vendor-invoice-entry-header-list', listFilter, {
      headers: { Authorization: `Bearer ${JSON.parse(token).value}` }
    })

    if (response.data.status == 200) {
      setInvoiceList(response.data.data);
      setInvoiceData(response.data.data)
    } else {
      toast.error(response.data.message, {
        theme: 'colored',
        autoClose: 10000
      });
      setInvoiceList([])
      setInvoiceData([])
    }
  }

  const getUnitList = async () => {
    let requestData = {
      UnitType: "W"
    }
    let response = await axios.post(process.env.REACT_APP_API_URL + '/unit-list', requestData)
    let unitListData = [];

    if (response.data.status == 200) {
      if (response.data && response.data.data.length > 0) {
        response.data.data.forEach(units => {
          unitListData.push({
            key: units.unitName,
            value: units.unitCode
          })
        })
        setUnitList(unitListData);
      }
    }
    else {
      setUnitList([]);
    }
  }

  const getInvoiceDetailList = async (invoiceNo) => {   
    const request = {
      InvoiceNo: invoiceNo
    }

    let response = await axios.post(process.env.REACT_APP_API_URL + '/get-vendor-invoice-entry-detail-list', request, {
      headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('Token')).value}` }
    })

    if (response.data.status == 200) {
      if (response.data.data && response.data.data.length > 0) {
        let netAmount = 0;
        const invoiceDetails = response.data.data.map(detail => {
          const unit = unitList.find(u => u.value === detail.unitCode);
          const unitName = unit ? unit.key : '';
          netAmount += parseFloat(detail.productAmount);
          let balanceAmount = (detail.paymentProductGrandAmt > 0 ? detail.paymentProductGrandAmt : detail.productGrandAmt) - detail.paidAmount
          let status = ""
          if ((detail.paymentProductGrandAmt ? detail.paymentProductGrandAmt : detail.productGrandAmt) == detail.paidAmount) {
            status = "Fully Paid"
          }
          else {
            status = "Partially Paid"
          }
          let taxIncluded
          if (detail.cgstAmt > 0 && detail.sgstAmt > 0) {
            taxIncluded = true
          }
          else {
            taxIncluded = false
          }
          return {
            ...detail,
            unitName: unitName,
            balanceAmount: balanceAmount,
            cgstPer: detail.paymentCGSTPer > 0 ? detail.paymentCGSTPer : detail.cgstPer ? detail.cgstPer : 0,
            cgstAmt: detail.paymentCGSTAmt > 0 ? detail.paymentCGSTAmt : detail.cgstAmt ? detail.cgstAmt : 0,
            sgstPer: detail.paymentSGSTPer > 0 ? detail.paymentSGSTPer : detail.sgstPer ? detail.sgstPer : 0,
            sgstAmt: detail.paymentSGSTAmt > 0 ? detail.paymentSGSTAmt : detail.sgstAmt ? detail.sgstAmt : 0,
            productGrandAmt: detail.paymentProductGrandAmt > 0 ? detail.paymentProductGrandAmt : detail.productGrandAmt,
            paidAmount: detail.paymentPaidAmount > 0 ? detail.paymentPaidAmount : detail.paidAmount ? detail.paidAmount : 0,
            netAmount: netAmount,
            status: status,
            taxIncluded: taxIncluded
          };
        });

        setInvoiceDetails(invoiceDetails)
        dispatch(paymentDetailsAction(invoiceDetails))

        var invoiceDetail = invoiceList.find(data => data.invoiceNo == invoiceNo);

        const totalInvoiceAmount = invoiceDetails.length > 1
          ? invoiceDetails.reduce((acc, obj) => {
            const invoiceAmount = obj.productGrandAmt !== "" ? parseFloat(obj.productGrandAmt) : 0;
            return acc + (isNaN(invoiceAmount) ? 0 : invoiceAmount);
          }, 0)
          : invoiceDetails.length === 1
            ? parseFloat(invoiceDetails[0].productGrandAmt)
            : 0;

        const invoicePaidAmount = invoiceDetails.length >= 1
          ? invoiceDetails.reduce((acc, obj) => {
            const paidAmount = obj.paidAmount !== "" ? parseFloat(obj.paidAmount) : 0;
            return acc + (isNaN(paidAmount) ? 0 : paidAmount);
          }, 0)
          : paymentDetails.length === 1
            ? parseFloat(paymentDetails[0].poAmt)
            : 0;

        let balanceAmount
        if (totalInvoiceAmount > 0) {
          balanceAmount = totalInvoiceAmount - invoicePaidAmount
        }

        let invoiceStatus = ""
        if (parseFloat(totalInvoiceAmount) == parseFloat(invoicePaidAmount)) {
          invoiceStatus = "Fully Paid"
        }
        else {
          invoiceStatus = "Partially Paid"
        }

        dispatch(paymentHeaderAction({
          ...paymentHeaderDetails,
          invoiceStatus: invoiceStatus,
          encryptedInvoiceHeaderCode: invoiceDetail.encryptedInvoiceHeaderCode,
          invoiceNo: invoiceDetail.invoiceNo,
          invoiceDate: invoiceDetail.invoiceDate,
          invoiceAmount: totalInvoiceAmount,
          gstTotalAmt: invoiceDetail.gstTotalAmt,
          invoiceGrandAmt: invoiceDetail.invoiceGrandAmt,
          poNo: invoiceDetail.poNo,
          invoicePaidAmount: isNaN(invoicePaidAmount) ? 0 : invoicePaidAmount,
          balanceAmount: isNaN(balanceAmount) ? 0 : balanceAmount
        }))

      } else {
        setInvoiceDetails([])
      }
    }
    else{
      toast.error(response.data.message, {
        theme: 'colored',
        autoClose: 10000
      });
    }
  }

  return (
    <>
      <Card className="mb-1">
        <Card.Body className='p5px'>
          <Row className="justify-content">
            <Col className='col-auto no-pd-card'>
              <Form.Group as={Row} className="mt-1">
                <h5 className='col-auto'>
                  {localStorage.getItem("CompanyName")}
                </h5>
              </Form.Group>
            </Col>
            <Col className='col-auto no-pd-card'>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label className='col-auto' column>
                  Company Name
                </Form.Label>
                <Col className='col-auto'>
                  <Form.Select id="txtCompanyCode" name="companyCode" onChange={handleFieldChange} value={paymentHeaderDetails.encryptedCompanyCode}>
                    <option value=''>Select</option>
                    {companyList.map((option, index) => (
                      <option key={index} value={option.value}>{option.key}</option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card >
      <Row className='mb-0'>
        <Col lg={2} className="no-pd-card no-right-pad margin-right-11">
          <Card className='mb-1'>
            <Card.Body className="fs--1 vendor-card-body">
              <Flex>
                <div className=" flex-1">
                  <Form.Group as={Row} className="" controlId="formPlaintextPassword">
                    <Col sm="12">
                      <Form.Control id="txtSearchVendor" name="searchVendor" placeholder="Search Vendor" maxLength={45} onChange={handleVendorAndFarmerOnChange} onBlur={() => companyValidation()}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Col>
                  </Form.Group>
                </div>
              </Flex>
            </Card.Body>
          </Card>
            <Card className="mb-1 ">
              <Card.Header
                as={Flex}
                alignItems="center"
                justifyContent="between"
                className="bg-light"
              >
                <h5 className="mb-0">Vendors</h5>
              </Card.Header>
              <Card.Body className='vebdor-card-item custom-card-scroll'>
                {vendorAndMasterDetail && vendorAndMasterDetail.map((item) => (
                  <div className="flex-1 " >
                    <h6 className="mb-0">
                      <Link to="" style={{ color: 'black' }} onClick={(e) => { e.preventDefault(); handleVendorAndFarmerDetail(item.code, item.name); }} >{item.name}</Link>
                    </h6>
                    <div className="border-dashed border-bottom my-1" />
                  </div>
                ))}
              </Card.Body>
            </Card>
        </Col>
        <Col lg={4} className="no-pd-card  no-right-pad ">
          <FalconComponentCard className="farmer-card-row1 ">
            <FalconCardBody className='card-box-padding' >
              <Form noValidate className="details-form" id='ClientUserDetailsForm'>
                <Row>
                  <Col className="me-3 ms-3">
                    <Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
                      <Form.Label column sm="4">
                        Name
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control id="txtName" placeholder="Name" name="name" value={paymentHeaderDetails.name} disabled >
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword" >
                      <Form.Label column sm="4">
                        Address
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control id="txtAddress" name="address" placeholder="Address" value={paymentHeaderDetails.address} disabled />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
                      <Form.Label column sm="4">
                        Pincode
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control id="txtPincode" name="pinCode" placeholder="Pincode" value={paymentHeaderDetails.pinCode} disabled />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
                      <Form.Label column sm="4">
                        State
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control id="txtState" name="state" placeholder="State" value={paymentHeaderDetails.state} disabled />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
                      <Form.Label column sm="4">
                        Country
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control id="txtCountry" name="country" placeholder="Country" value={paymentHeaderDetails.country} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </FalconCardBody>
          </FalconComponentCard>
        </Col>
        <Col lg={4} className="no-pd-card no-right-pad no-left-pad">
          <FalconComponentCard className="farmer-card-row1">
            <FalconCardBody className='card-box-padding'>
              <Form noValidate className="details-form" id='ClientUserDetailsForm'>
                <Row>
                  <Col className="me-3 ms-3">
                    <Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
                      <Form.Label column sm="4">
                        Invoice No
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control id="txtInvoiceNo" placeholder="Invoice No" name="invoiceNo" value={paymentHeaderDetails.invoiceNo} disabled >
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
                      <Form.Label column sm="4">
                        Invoice Date
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control id="txtInvoiceDate" placeholder="Invoice Date" name="invoiceDate" value={paymentHeaderDetails.invoiceDate} disabled >
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword" >
                      <Form.Label column sm="4">
                        Invoice Amount
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control id="txtInvoiceAmount" name="invoiceAmount" placeholder="Invoice Amount" value={paymentHeaderDetails.invoiceAmount} disabled />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
                      <Form.Label column sm="4">
                        Paid Amount<span className="text-danger">*</span>
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control id="txtInvoicePaidAmount" name="invoicePaidAmount" placeholder="Paid Amount" value={paymentHeaderDetails.invoicePaidAmount} onChange={handleFieldChange} disabled={paymentHeaderDetails.invoiceStatus == "Fully Paid"}
                         onKeyPress={handleNumericInputKeyPress} />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
                      <Form.Label column sm="4">
                      Bal. Amount
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control id="txtBalanceAmount" name="balanceAmount" placeholder="Balance Amount" value={paymentHeaderDetails.balanceAmount} disabled />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </FalconCardBody>
          </FalconComponentCard>
        </Col>
        <Col lg={2} className="no-pd-card  margin-left-11">
          <Card className='mb-1'>
            <Card.Body className="fs--1 vendor-card-body ">
              <Flex>
                <div className=" flex-1">
                  <Form.Group as={Row} className="" controlId="formPlaintextPassword">
                    <Col sm="12">
                      <Form.Control id="txtSearchInvoice" className='' name="searchInvoice" placeholder="Search Invoice" maxLength={45} onChange={handleInvoiceOnChange} onBlur={() => vendorValidation()}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Col>
                  </Form.Group>
                </div>
              </Flex>
            </Card.Body>
          </Card>
            <Card className="mb-1 ">
              <Card.Header
                as={Flex}
                alignItems="center"
                justifyContent="between"
                className="bg-light"
              >
                <h5 className="mb-0">Invoices</h5>
              </Card.Header>
              <Card.Body className='custom-card-invoice-scroll vebdor-card-invoice-item '>
                {invoiceData && invoiceData.length > 0 && invoiceData.map((item) => (
                  <div className="flex-1 ">
                    <h6 className="mb-0">
                      <Link style={{ color: 'black' }} onClick={(e) => { e.preventDefault(); handleInvoiceDetail(item.invoiceNo); }}>{item.invoiceNo}</Link>
                    </h6>
                    <div className="border-dashed border-bottom my-1" />
                  </div>
                ))}
              </Card.Body>
            </Card>
        </Col>
      </Row>
    </>
  )
}

export default AddPaymentDetails