import React, { useEffect, useState } from 'react';
import { Form, Card, Table, Modal, Button, Spinner } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import EnlargableTextbox from 'components/common/EnlargableTextbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faSave } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import { demandDistributionProductDetailAction, demandDistributionProductAction } from 'actions';

const AddDemandDistributedProductDeatil = () => {
	useEffect(() => {
	}, []);

	const dispatch = useDispatch();
	const [editingRow, setEditingRow] = useState(null);
	const [updatedQty, setUpdatedQty] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [paramsData, setParamsData] = useState({});


	const demandDistributionProductDetail = useSelector((state) => state.rootReducer.demandDistributionProductDetailReducer.demandDistributionProductDetail);
	const demandDistributionProduct = useSelector((state) => state.rootReducer.demandDistributionProductReducer.demandDistributionProduct);

	const handleEditClick = (index, qty) => {
		setEditingRow(index);
		setUpdatedQty(qty);
	};

	const handleQtyChange = (e) => {
		setUpdatedQty(e.target.value);
	};

	const handleSaveClick = async (demandProductDetail) => {
		let token = localStorage.getItem('Token');

		const request = {
			encryptedDemandDistributionDetailId: demandProductDetail.encryptedDemandDistributionDetailId,
			demandDetailId : demandProductDetail.demandDetailId,
			oldDeliveredQty : demandProductDetail.distributedQty,
			NewDistributedQty : updatedQty,
			productCode: demandProductDetail.productCode,
			productCategoryCode: demandProductDetail.productCategoryCode,
			modify_User: localStorage.getItem('LoginUserName')
		};

		setIsLoading(true);
		let response = await axios.post(
			process.env.REACT_APP_API_URL + '/update-demand-distribution-detail',
			request,
			{
				headers: { Authorization: `Bearer ${JSON.parse(token).value}` }
			}
		);
		if (response.data.status == 200) {
			setIsLoading(false)
			toast.success(response.data.message, {
				theme: 'colored',
				autoClose: 10000
			})
			const demandProductIndex = demandDistributionProduct.findIndex(distribution =>  distribution.demandDetailId ===  demandProductDetail.demandDetailId);
			
			if (demandProductIndex !== -1) {
				const updatedDemandDistributionProduct = [...demandDistributionProduct];
				updatedDemandDistributionProduct[demandProductIndex] = {
					...updatedDemandDistributionProduct[demandProductIndex],
					demandDistributedQty: updatedDemandDistributionProduct[demandProductIndex].demandDistributedQty - parseFloat(request.oldDeliveredQty) + parseFloat(request.NewDistributedQty)
				};
				dispatch(demandDistributionProductAction(updatedDemandDistributionProduct));
			}
			getDemandDistributionDetail(demandProductDetail.encryptedDemandDetailId);
			
		} else {
			setIsLoading(false)
			toast.error(response.data.message, {
				theme: 'colored',
				autoClose: 10000
			});
		}
		setEditingRow(null);
		setUpdatedQty('');
	};

	const ModalPreview = (demandProductDetail) => {
		setModalShow(true);
		setParamsData({ demandProductDetail });
	}

	const deleteDemandDistributionDetail = async () => {
		if (!paramsData)
			return false;
	
		const data = {
			encryptedDemandDistributionDetailId: paramsData.demandProductDetail.encryptedDemandDistributionDetailId,
			demandDetailId : paramsData.demandProductDetail.demandDetailId,
			deliveredQty : paramsData.demandProductDetail.distributedQty,
			productCode: paramsData.demandProductDetail.productCode,
			productCategoryCode: paramsData.demandProductDetail.productCategoryCode,
			modify_User: localStorage.getItem('LoginUserName')
		};
		const headers = { Authorization: `Bearer ${JSON.parse(localStorage.getItem('Token')).value}` }

		setIsLoading(true);
		let response = await axios.delete(process.env.REACT_APP_API_URL + '/delete-demand-distribution-detail', { headers, data });
		
		if (response.data.status == 200) {
			setIsLoading(false)

			const demandProductIndex = demandDistributionProduct.findIndex(distribution =>  distribution.demandDetailId ===  data.demandDetailId);
			if (demandProductIndex !== -1) {
				const updatedDemandDistributionProduct = [...demandDistributionProduct];
				updatedDemandDistributionProduct[demandProductIndex] = {
					...updatedDemandDistributionProduct[demandProductIndex],
					demandDistributedQty: updatedDemandDistributionProduct[demandProductIndex].demandDistributedQty - parseFloat(data.deliveredQty)
				};
				dispatch(demandDistributionProductAction(updatedDemandDistributionProduct));
			}

			getDemandDistributionDetail(paramsData.demandProductDetail.encryptedDemandDetailId);
			toast.success(response.data.message, {
				theme: 'colored',
				autoClose: 10000
			})
			
		} else {
			setIsLoading(false)
			toast.error(response.data.message, {
				theme: 'colored',
				autoClose: 10000
			});
		}
		setModalShow(false);	
	}

	const getDemandDistributionDetail = async (demandDetailId) => {
		let token = localStorage.getItem('Token');

		const listFilter = {
			encryptedDemandDetailId: demandDetailId
		};

		setIsLoading(true);
		let response = await axios.post(
			process.env.REACT_APP_API_URL + '/get-demand-distribution-list',
			listFilter,
			{
				headers: { Authorization: `Bearer ${JSON.parse(token).value}` }
			}
		);

		if (response.data.status == 200) {
			setIsLoading(false);
			dispatch(demandDistributionProductDetailAction(response.data.data));
		} else {
			setIsLoading(false);
			dispatch(demandDistributionProductDetailAction(undefined));
		}
	}


	return (
		<>
			{isLoading ? (
				<Spinner
					className="position-absolute start-50 translate-middle loader-color"
					animation="border"
				/>
			) : null}
		{modalShow && paramsData &&
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Are you sure, you want to delete this Demand Distribution detail?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => setModalShow(false)}>Cancel</Button>
            <Button variant="danger" onClick={() => deleteDemandDistributionDetail()}>Delete</Button>
          </Modal.Footer>
        </Modal>
      }
			{demandDistributionProductDetail && demandDistributionProductDetail.length > 0 &&
			<Card className="h-100 mb-2">
				<FalconCardHeader
					title="Distribution Transactions"
					titleTag="h6"
					className="py-2"
					light
				/>
				<Card.Body className="position-relative pb-0 p3px">
					<Form
						noValidate

						className="details-form"
						id="AddDemandProductDetailsForm"
					>
						<Table
							striped
							bordered
							responsive
							id="TableList"
							className="no-pb text-nowrap tab-page-table"
						>
							<thead className="custom-bg-200">
								<tr>
									<th>Sr.No</th>
									<th>Date Time</th>
									<th>Product Name</th>
									<th>Brand</th>
									<th>Distributed Qty</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody id="tbody" className="details-form">
							{demandDistributionProductDetail.map((demandProductDetail, index) => (
										<tr key={index}>
									<td>{++index}</td>
									<td>{moment(demandProductDetail.addDate).format('YYYY-MM-DD, hh:mm A')}</td>
									<td>{`${demandProductDetail.productName} (${demandProductDetail.productCategoryName})`}</td>
									<td>{demandProductDetail.brandName}</td>
									{/* <td>{demandProductDetail.distributedQty}</td> */}
									<td>
									{editingRow === index ? (
											<EnlargableTextbox
												name={`distributed${index}`}
												type="text" 
												value={updatedQty} 
												onChange={handleQtyChange} 
											/>
										) : (
											demandProductDetail.distributedQty
										)}
									</td>
									<td>
									{editingRow === index ? (
											<FontAwesomeIcon icon={faSave} className="fa-2x" onClick={() => handleSaveClick(demandProductDetail)} />
										) : (
											<FontAwesomeIcon icon={faEdit} className="fa-2x" onClick={() => handleEditClick(index, demandProductDetail.distributedQty)} />
										)}
										{/* <FontAwesomeIcon icon={faEdit} className="fa-2x" onClick={() => handleEditClick(index, demandProductDetail.distributedQty)} /> {' '} */}
										<FontAwesomeIcon icon={faTrash} className="fa-2x" onClick={() => { ModalPreview(demandProductDetail) }}/>
									</td>
								</tr>
							))}
							</tbody>
						</Table>
					</Form>
				</Card.Body>
				{/* )} */}
			</Card>
			}
		</>
	);

};

export default AddDemandDistributedProductDeatil;
