import React, { useState, useEffect } from 'react'
import TabPage from 'components/common/TabPage';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Spinner, Modal, Button } from 'react-bootstrap';
import $ from "jquery";
import { toast } from 'react-toastify';
import { distributionCentreListAction, formChangedAction, purchaseOrderDetailsAction, purchaseOrderDetailsErrAction, purchaseOrderProductDetailsAction, purchaseOrderTermDetailsAction, tabInfoAction, vendorProductCatalogueDetailsAction } from 'actions';
import Moment from "moment";

const tabArray = ['Add PO', 'Add Term']

const DemandPurchaseOrder = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [companyList, setCompanyList] = useState([]);
	const [companyName, setCompanyName] = useState(localStorage.getItem("CompanyName"));
	const [listData, setListData] = useState([]);
	const [perPage, setPerPage] = useState(15);
	const [activeTabName, setActiveTabName] = useState();
	const [formHasError, setFormError] = useState(false);
	const [modalShow, setModalShow] = useState(false);

	useEffect(() => {
		$("#btnNew").hide();
		$("#btnSave").show();
		$("#btnCancel").show();
		$('[data-rr-ui-event-key*="Add PO"]').trigger('click');
		$('[data-rr-ui-event-key*="Add PO"]').attr('disabled', false);
		$('[data-rr-ui-event-key*="Add Term"]').attr('disabled', false);
		localStorage.removeItem("EncryptedPoNo");
		fetchDistributionCentreList(localStorage.getItem("EncryptedCompanyCode"));
	}, [])

	const purchaseOrderDetailsReducer = useSelector((state) => state.rootReducer.purchaseOrderDetailsReducer)
	var purchaseOrderData = purchaseOrderDetailsReducer.purchaseOrderDetails;

	let purchaseOrderProductDetailsReducer = useSelector((state) => state.rootReducer.purchaseOrderProductDetailsReducer)
	let purchaseOrderProductDetailsList = purchaseOrderProductDetailsReducer.purchaseOrderProductDetails;

	let purchaseOrderTermDetailsReducer = useSelector((state) => state.rootReducer.purchaseOrderTermDetailsReducer)
	let purchaseOrderTermList = purchaseOrderTermDetailsReducer.purchaseOrderTermDetails;

	const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
	var formChangedData = formChangedReducer.formChanged;

	let isFormChanged = Object.values(formChangedData).some(value => value === true);

	const fetchPurchaseOrderList = async (page, size = perPage, encryptedCompanyCode) => {

		let token = localStorage.getItem('Token');

		const listFilter = {
			pageNumber: page,
			pageSize: size,
			EncryptedCompanyCode: encryptedCompanyCode
		}

		setIsLoading(true);
		let response = await axios.post(process.env.REACT_APP_API_URL + '/get-po-header-list', listFilter, {
			headers: { Authorization: `Bearer ${JSON.parse(token).value}` }
		})

		if (response.data.status == 200) {
			setIsLoading(false);
			setListData(response.data.data);
		} else {
			setIsLoading(false);
			setListData([])
		}
	}

	const fetchDistributionCentreList = async (encryptedCompanyCode) => {
		const request = {
			EncryptedClientCode: localStorage.getItem("EncryptedClientCode"),
			EncryptedCompanyCode: encryptedCompanyCode
		}

		let response = await axios.post(process.env.REACT_APP_API_URL + '/get-distribution-centre-list', request, {
			headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('Token')).value}` }
		})
		let distributionCentreListData = [];
		if (response.data.status == 200) {
			if (response.data && response.data.data.length > 0) {
				response.data.data.forEach(distributionCentre => {
					distributionCentreListData.push({
						key: distributionCentre.distributionName,
						value: distributionCentre.distributionCentreCode
					})
				})
			}
			dispatch(distributionCentreListAction(distributionCentreListData));
		}
	}

	$('[data-rr-ui-event-key*="PO List"]').off('click').on('click', function () {
		let isDiscard = $('#btnDiscard').attr('isDiscard');
		if (isDiscard != 'true' && isFormChanged) {
			setModalShow(true);
			setTimeout(function () {
				$('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
			}, 50);
		} else {
			$("#btnNew").show();
			$("#btnSave").hide();
			$("#btnCancel").hide();
			$('[data-rr-ui-event-key*="Add PO"]').attr('disabled', true);
			$('[data-rr-ui-event-key*="Add Term"]').attr('disabled', true);
			clearPurchaseOrderReducers();
			dispatch(purchaseOrderDetailsAction(undefined));
			dispatch(vendorProductCatalogueDetailsAction([]));
			localStorage.removeItem("EncryptedPoNo");
			localStorage.removeItem("OldPoStatus");
		}
	})

	$('[data-rr-ui-event-key*="Add PO"]').off('click').on('click', function () {
		setActiveTabName("Add PO")
		$("#btnNew").hide();
		$("#btnSave").show();
		$("#btnCancel").show();
		$('[data-rr-ui-event-key*="Add Term"]').attr('disabled', false);

		if (purchaseOrderProductDetailsList.length <= 0 &&
			!(localStorage.getItem("DeletePurchaseOrderProductDetailIds"))) {
			getPurchaseOrderProductDetailsList()
		}

		if (purchaseOrderTermList.length <= 0 &&
			!(localStorage.getItem("DeletePurchaseOrderTermDetailIds"))) {
			getPurchaseOrderTermDetailsList();
		}
	})

	$('[data-rr-ui-event-key*="Add Term"]').off('click').on('click', function () {
		setActiveTabName("Add Term")
		$("#btnNew").hide();
		$("#btnSave").show();
		$("#btnCancel").show();

		if (purchaseOrderTermList.length <= 0 &&
			!(localStorage.getItem("DeletePurchaseOrderTermDetailIds"))) {
			getPurchaseOrderTermDetailsList();
		}

		if (purchaseOrderProductDetailsList.length <= 0 &&
			!(localStorage.getItem("DeletePurchaseOrderProductDetailIds"))) {
			getPurchaseOrderProductDetailsList()
		}
	})

	const newDetails = () => {

		if (localStorage.getItem("EncryptedCompanyCode") && localStorage.getItem("CompanyName")) {
			$('[data-rr-ui-event-key*="Add PO"]').attr('disabled', false);
			$('[data-rr-ui-event-key*="Add PO"]').trigger('click');
			$('[data-rr-ui-event-key*="Add Term"]').attr('disabled', false);
			$('#btnSave').attr('disabled', false);
			dispatch(tabInfoAction({ title1: `${localStorage.getItem("CompanyName")}` }))
		} else {
			toast.error("Please select company first", {
				theme: 'colored',
				autoClose: 5000
			});
		}
	}

	const cancelClick = () => {
		$('#btnExit').attr('isExit', 'false');
		if (isFormChanged) {
			setModalShow(true);
		} else {
			$('[data-rr-ui-event-key*="PO List"]').trigger('click');
		}
	}

	const exitModule = () => {
		$('#btnExit').attr('isExit', 'true');
		if (isFormChanged) {
			setModalShow(true);
		} else {
			window.location.href = '/dashboard';
			clearPurchaseOrderReducers();
			dispatch(purchaseOrderDetailsAction(undefined));
			dispatch(vendorProductCatalogueDetailsAction([]));
			localStorage.removeItem("EncryptedPoNo");
			localStorage.removeItem("DeletePoProductDetailIds");
			localStorage.removeItem("DeletePoTermDetailIds");
			localStorage.removeItem("EncryptedCompanyCode");
			localStorage.removeItem("CompanyName");
		}
	}

	const discardChanges = () => {
		$('#btnDiscard').attr('isDiscard', 'true');
		if ($('#btnExit').attr('isExit') == 'true')
			window.location.href = '/dashboard';
		else {
			$('[data-rr-ui-event-key*="PO List"]').trigger('click');
			// window.location.href = '/demand-consolidation';
			// navigate('/purchase-order', { state: localStorage.getItem('EncryptedCompanyCode') });
		}

		setModalShow(false);
	}

	const getPurchaseOrderProductDetailsList = async () => {
		const request = {
			EncryptedPoNo: localStorage.getItem("EncryptedPoNo")
		}

		let response = await axios.post(process.env.REACT_APP_API_URL + '/get-po-detail-list', request, {
			headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('Token')).value}` }
		})

		if (response.data.status == 200) {
			if (response.data.data && response.data.data.length > 0) {
				dispatch(purchaseOrderProductDetailsAction(response.data.data));
			}
		}
	}

	const getPurchaseOrderTermDetailsList = async () => {
		const request = {
			EncryptedPoNo: localStorage.getItem("EncryptedPoNo")
		}

		let response = await axios.post(process.env.REACT_APP_API_URL + '/get-po-term-detail-list', request, {
			headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('Token')).value}` }
		})

		if (response.data.status == 200) {
			if (response.data.data && response.data.data.length > 0) {
				dispatch(purchaseOrderTermDetailsAction(response.data.data))
			}
		}
	}

	const clearPurchaseOrderReducers = () => {
		dispatch(formChangedAction(undefined));
		dispatch(purchaseOrderProductDetailsAction([]));
		dispatch(purchaseOrderTermDetailsAction([]));
		dispatch(purchaseOrderDetailsErrAction(undefined));
		localStorage.removeItem("DeletePoProductDetailIds");
		localStorage.removeItem("DeletePoTermDetailIds");
	}

	const purchaseOrderValidation = () => {
		setModalShow(false);

		const vendorErr = {};
		const poDateErr = {};
		const poAmountErr = {};
		const poProductDetailsErr = {};

		let isValid = true;

		if (!purchaseOrderData.vendorCode) {
			vendorErr.empty = "Select vendor";
			isValid = false;
			setFormError(true);
		}

		if (!purchaseOrderData.poDate) {
			poDateErr.empty = "Select PO date";
			isValid = false;
			setFormError(true);
		}

		if (purchaseOrderProductDetailsList.length < 1) {
			poProductDetailsErr.poProductDetailEmpty = "At least one purchase order product detail required";
			setTimeout(() => {
				toast.error(poProductDetailsErr.poProductDetailEmpty, {
					theme: 'colored'
				});
			}, 1000);
			isValid = false;
		}
		else if (purchaseOrderProductDetailsList && purchaseOrderProductDetailsList.length > 0) {
			purchaseOrderProductDetailsList.forEach((row, index) => {
				if (!row.unitCode || !row.quantity || !row.poRate || !row.poAmt) {
					poProductDetailsErr.invalidPoProductDetail = "Fill the required fields in purchase order product detail";
					isValid = false;
					setFormError(true);
				}
			})
		}

		const totalProductGrandAmount = purchaseOrderProductDetailsList.length > 1
			? purchaseOrderProductDetailsList.reduce((acc, obj) => {
				const productGrandAmount = obj.productGrandAmt !== "" ? parseFloat(obj.productGrandAmt) : 0;
				return acc + (isNaN(productGrandAmount) ? 0 : productGrandAmount);
			}, 0)
			: purchaseOrderProductDetailsList.length === 1
				? parseFloat(purchaseOrderProductDetailsList[0].productGrandAmt)
				: 0;

		if (purchaseOrderData.poAmount != totalProductGrandAmount) {
			poAmountErr.empty = "Po amount should be equal to total grand product amount";
			setTimeout(() => {
				toast.error(poAmountErr.empty, {
					theme: 'colored'
				});
			}, 1000);
			isValid = false;
		}

		if (!isValid) {
			var errorObject = {
				vendorErr,
				poDateErr,
				poProductDetailsErr,
				poAmountErr
			}

			dispatch(purchaseOrderDetailsErrAction(errorObject))
		}

		return isValid;
	}

	const updatePurchaseOrderCallback = (isAddPurchaseOrder = false) => {
		setModalShow(false);

		if (!isAddPurchaseOrder) {
			toast.success("Purchase order details updated successfully", {
				time: 'colored'
			})
		}

		$('#btnSave').attr('disabled', true)

		navigate('/purchase-order', { state: localStorage.getItem('EncryptedCompanyCode') });
		clearPurchaseOrderReducers();

		fetchPurchaseOrderList(1, perPage, localStorage.getItem("EncryptedCompanyCode"));

		$('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
	}

	const getDemandPoHeaderDetail = (encryptedPoNo) => {
		const requestData = {
			encryptedPoNo: encryptedPoNo
		}

		axios.post(process.env.REACT_APP_API_URL + '/get-demand-po-header-detail', requestData, {
			headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('Token')).value}` }
		})
			.then(res => {
				if (res.data.status == 200) {
					if (res.data.data) {
						let demandPoDetails = res.data.data;
						localStorage.setItem('EncryptedPoNo', demandPoDetails.encryptedPoNo);
						localStorage.setItem('OldPoStatus', demandPoDetails.poStatus)
						dispatch(purchaseOrderDetailsAction(demandPoDetails));
						$('#btnSave').attr('disabled', true);
						dispatch(tabInfoAction({
							title1: companyName
						}))

						if (demandPoDetails.farmerCode) {
							$('[data-rr-ui-event-key*="Add Crop Purchase"]').attr('disabled', false);
							$('[data-rr-ui-event-key*="Add Crop Purchase"]').trigger('click');
						}
						else if (demandPoDetails.vendorCode) {
							$('[data-rr-ui-event-key*="Add PO"]').attr('disabled', false);
							$('[data-rr-ui-event-key*="Add PO"]').trigger('click');
						}
					}
				}
			});

	}

	const addPurchaseOrderDetails = async () => {
		if (purchaseOrderValidation()) {
			const purchaseOrderProductDetailsLists = purchaseOrderProductDetailsList.map(detail => {
				return {
					...detail,
					cgstPer: detail.cgstPer ? detail.cgstPer : 0,
					cgstAmt: detail.cgstAmt ? detail.cgstAmt : 0,
					sgstPer: detail.sgstPer ? detail.sgstPer : 0,
					sgstAmt: detail.sgstAmt ? detail.sgstAmt : 0,
					productGrandAmt: detail.productGrandAmt ? detail.productGrandAmt : 0,
				};
			});

			const requestData = {
				encryptedClientCode: localStorage.getItem("EncryptedClientCode"),
				encryptedCompanyCode: purchaseOrderData.encryptedCompanyCode,
				distributionCentreCode: purchaseOrderData.distributionCentreCode ? purchaseOrderData.distributionCentreCode : "",
				collectionCentreCode: purchaseOrderData.collectionCentreCode ? purchaseOrderData.collectionCentreCode : "",
				vendorCode: purchaseOrderData.vendorCode,
				poDate: Moment(purchaseOrderData.poDate).format("YYYY-MM-DD"),
				poAmount: parseFloat(purchaseOrderData.poAmount),
				poStatus: purchaseOrderData.poStatus ? purchaseOrderData.poStatus : "Draft",
				gstNo: purchaseOrderData.gstNo ? purchaseOrderData.gstNo : "",
				activeStatus: "A",
				purchaseOrderProductDetails: purchaseOrderProductDetailsLists,
				purchaseOrderTermDetails: purchaseOrderTermList,
				deliveryLocation: purchaseOrderData.deliveryLocation ? purchaseOrderData.deliveryLocation : "",
				totalPOQty: purchaseOrderData.totalPOQty ? purchaseOrderData.totalPOQty : 0,
				gstTotalAmt: purchaseOrderData.gstTotalAmt,
				poGrandAmt: purchaseOrderData.poGrandAmt,
				addUser: localStorage.getItem("LoginUserName")
			}

			const keys = ['addUser']
			for (const key of Object.keys(requestData).filter((key) => keys.includes(key))) {
				requestData[key] = requestData[key] ? requestData[key].toUpperCase() : "";
			}

			const poTermDetailKeys = ['poTerms', 'addUser']
			var index = 0;
			for (var obj in requestData.purchaseOrderTermDetails) {
				var poTermDetailObject = requestData.purchaseOrderTermDetails[obj];

				for (const key of Object.keys(poTermDetailObject).filter((key) => poTermDetailKeys.includes(key))) {
					poTermDetailObject[key] = poTermDetailObject[key] ? poTermDetailObject[key].toUpperCase() : "";
				}

				requestData.purchaseOrderTermDetails[index] = poTermDetailObject;
				index++;
			}

			setIsLoading(true);
			const res = await axios.post(process.env.REACT_APP_API_URL + '/add-po-header-detail', requestData, {
				headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('Token')).value}` }
			})
					if (res.data.status == 200) {
						setIsLoading(false)
						setTimeout(function () {
							dispatch(purchaseOrderDetailsAction({
								...purchaseOrderData,
								encryptedPoNo: res.data.data.encryptedPoNo,
								poNo: res.data.data.poNo
							}))
						}, 50);
						localStorage.setItem("EncryptedPoNo", res.data.data.encryptedPoNo);
						localStorage.setItem("OldPoStatus", requestData.poStatus);
						localStorage.setItem("EncryptedCompanyCode", purchaseOrderData.encryptedCompanyCode);
						// localStorage.setItem("CompanyName", purchaseOrderData.companyName);
						toast.success(res.data.message, {
							theme: 'colored',
							autoClose: 10000
						});
						getDemandPoHeaderDetail(res.data.data.encryptedPoNo);

						updatePurchaseOrderCallback(true);
					} else {
						setIsLoading(false)
						toast.error(res.data.message, {
							theme: 'colored',
							autoClose: 10000
						});
					}
		}
	}

	return (
		<>
			{isLoading ? (
				<Spinner
					className="position-absolute start-50 loader-color"
					animation="border"
				/>
			) : null}

			{modalShow &&
				<Modal
					show={modalShow}
					onHide={() => setModalShow(false)}
					size="md"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">Confirmation</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h5>Do you want to save changes?</h5>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="success" onClick={addPurchaseOrderDetails}>Save</Button>
						<Button variant="danger" id='btnDiscard' onClick={discardChanges}>Discard</Button>
					</Modal.Footer>
				</Modal>
			}

			<TabPage
				// listData={listData}
				// listColumnArray={listColumnArray}
				tabArray={tabArray}
				module="DemandPurchaseOrder"
				saveDetails={addPurchaseOrderDetails}
				newDetails={newDetails}
				cancelClick={cancelClick}
				exitModule={exitModule}
				tableFilterOptions={companyList}
				tableFilterName={'Company'}
			// supportingMethod1={handleFieldChange}
			/>
		</>

	)
}

export default DemandPurchaseOrder