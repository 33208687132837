const initialState = {
	demandDistributionFilterError: {
		sowingYearErr: {},
		farmerCodeErr: {}
	}
};

const demandDistributionErrorReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'DEMANDDISTRIBUTIONERROR':
			if (!action.payload) {
				return initialState
			}
			else {
				return {
					...state,
					demandDistributionFilterError: action.payload
				}
			}
		default:
			return state;
	}
};

export default demandDistributionErrorReducer;