import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Col, Form, Row, Card, Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { demandDistributionAction, formChangedAction, demandDistributionErrAction, demandDistributionProductAction, demandDistributionProductDetailAction } from 'actions';
import { toast } from 'react-toastify';

const AddDemandDistribute = () => {
	const today = useMemo(() => new Date().toISOString().split('T')[0], []);

	const dispatch = useDispatch();

	const demandDistributionDetail = useSelector((state) => state.rootReducer.demandDistributionReducer.demandDistributionDetail);
	const formChangedReducer = useSelector(
		state => state.rootReducer.formChangedReducer
	);
	var formChangedData = formChangedReducer.formChanged;

	let isFormChanged = Object.values(formChangedData).some(
		value => value === true
	);

	const demandDistributionErrorReducer = useSelector((state) => state.rootReducer.demandDistributionErrorReducer)
	const distributionFilterError = demandDistributionErrorReducer.demandDistributionFilterError;  

	useEffect(() => {
		if (isFormChanged) {
			$("#btnSave").attr('disabled', false);
		} else {
			$("#btnSave").attr('disabled', true);
		}
	}, []);

	const [isLoading, setIsLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [farmerDetailsList, setFarmerDetailsList] = useState([]);
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
	const [yearList, setYearList] = useState(Array.from({ length: 11 }, (_, i) => ({
		value: currentYear - i,
		key: String(currentYear - i)
	})));
	const [showFarmerDropdown, setShowFarmerDropdown] = useState(true);
	const [showSearchFarmerValue, setShowSearchFarmerValue] = useState("");
	const [selectedSowingYear, setSelectedSowingYear] = useState(new Date().getFullYear());
	const [selectedSowingMonth, setSelectedSowingMonth] = useState("");

	const months = [
		{ name: 'January', value: '01' },
		{ name: 'February', value: '02' },
		{ name: 'March', value: '03' },
		{ name: 'April', value: '04' },
		{ name: 'May', value: '05' },
		{ name: 'June', value: '06' },
		{ name: 'July', value: '07' },
		{ name: 'August', value: '08' },
		{ name: 'September', value: '09' },
		{ name: 'October', value: '10' },
		{ name: 'November', value: '11' },
		{ name: 'December', value: '12' }
	];

	useEffect(() => {
		dispatch(demandDistributionAction({
			...demandDistributionDetail,
			sowingYear: currentYear
		}))
	}, []);

	const resetFarmerDetail = useCallback(() => {
		dispatch(
			demandDistributionAction({
				...demandDistributionDetail,
				farmerCode: '',
				encryptedFarmerCode: '',
				farmerName: '',
				fatherName: '',
				village: '',
				phoneNumber: '',			
			})
		);
	}, [dispatch, demandDistributionDetail]);

	const getFarmerDetailsList = useCallback(async (searchText) => {
		const requestData = {
			pageNumber: 1,
			pageSize: 10,
			EncryptedCompanyCode: localStorage.getItem('EncryptedCompanyCode'),
			EncryptedClientCode: localStorage.getItem('EncryptedClientCode'),
			searchText: searchText
		};

		try {
			const response = await axios.post(
				process.env.REACT_APP_API_URL + '/farmer-list',
				requestData,
				{
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem('Token')).value
							}`
					}
				}
			);

			if (response.data.status === 200 && response.data.data.length > 0) {
				resetFarmerDetail();
				setFarmerDetailsList(response.data.data);
				setShowFarmerDropdown(true);
			} else {
				setFarmerDetailsList([]);
				resetFarmerDetail();
			}
		} catch (error) {
			console.error('Error fetching farmer details:', error);
		}
	}, []);

	const getFarmerDetailByCardNo = useCallback(async (searchText) => {
		const requestData = {
			CardNo: searchText
		}

		try {
			let response = await axios.post(process.env.REACT_APP_API_URL + '/get-farmer-detail-by-card-no', requestData, {
				headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem('Token')).value}` }
			})

			if (response.data.status == 200) {
				setFarmerDetailsList([]);
				setShowFarmerDropdown(false);
				dispatch(demandDistributionAction({
					...demandDistributionDetail,
					farmerCode: response.data.data.farmerCode,
					farmerName: response.data.data.farmerName,
					fatherName: response.data.data.farmerFatherName,
					phoneNumber: response.data.data.farmerPhoneNumber,
					village: response.data.data.village + ", " + response.data.data.districtName + ", " + response.data.data.stateName,
					cardNo: response.data.data.cardNo
				}))
			}
			else {
				if (response.data.status == 205) {
					toast.error("Provided card number is inactive", {
						theme: 'colored'
					});
				} else {
					toast.error(response.data.message, {
						theme: 'colored'
					});
				}

				setFarmerDetailsList([]);
				resetFarmerDetail();
			}


		} catch (error) {
			console.error('Error fetching farmer details:', error);
		}
	}, [resetFarmerDetail]);

	const handleFarmerOnChange = useCallback((e) => {
		const searchText = e.target.value;
		setShowSearchFarmerValue(searchText);
		if (searchText !== "") {
			if (/^\d{10}$/.test(searchText)) {
				getFarmerDetailByCardNo(searchText);
			} else {
				getFarmerDetailsList(searchText);
			}
		} else {
			dispatch(demandDistributionProductAction(undefined));
			dispatch(demandDistributionProductDetailAction(undefined));
			setFarmerDetailsList([]);
			setShowFarmerDropdown(false);
			setShowSearchFarmerValue("");
		}
	}, []);

	const handleSowingYearChange = (event) => {
		setSelectedSowingYear(event.target.value);
		dispatch(demandDistributionAction({
			...demandDistributionDetail,
			sowingYear: event.target.value,
		}));
		dispatch(demandDistributionProductAction(undefined));
		dispatch(demandDistributionProductDetailAction(undefined));
	};

	const handleSowingMonthChange = (event) => {
		setSelectedSowingMonth(event.target.value);
		dispatch(demandDistributionAction({
			...demandDistributionDetail,
			sowingMonth: event.target.value,
		}));
		dispatch(demandDistributionProductAction(undefined));
		dispatch(demandDistributionProductDetailAction(undefined));
	};

	const handleFarmerDetail = useCallback((farmerCode, farmerName) => {
		const farmerDetail = farmerDetailsList.find(farmer => farmer.farmerCode === farmerCode && farmer.farmerName === farmerName);
		dispatch(demandDistributionAction({
			...demandDistributionDetail,
			farmerCode: farmerDetail.farmerCode,
			encryptedFarmerCode: farmerDetail.encryptedFarmerCode,
			farmerName: farmerDetail.farmerName,
			fatherName: farmerDetail.farmerFatherName,
			village: farmerDetail.village,
			phoneNumber: farmerDetail.farmerPhoneNumber,
			sowingYear: $('#sowingYear').val(),
			sowingMonth: $('#sowingMonth').val(),
		}));
		setShowFarmerDropdown(false);
		setFarmerDetailsList([]);
		// resetFarmerDetail();

	}, [farmerDetailsList]);

	const farmerDemandFilterValidation = () => {
		const farmerCodeErr = {};
		const sowingYearErr = {};

		let isValid = true;

		if (!demandDistributionDetail.farmerCode) {
			farmerCodeErr.empty = "Select Farmer";
			isValid = false;
			// setFormError(true);
		}

		if (!demandDistributionDetail.sowingYear) {
			sowingYearErr.empty = "Select Sowing Year";
			isValid = false;
			// setFormError(true);
		}

		if (!isValid) {
			var errorObject = {
				farmerCodeErr,
				sowingYearErr
			}

			dispatch(demandDistributionErrAction(errorObject))
		}
		else {
			dispatch(demandDistributionErrAction())
		}

		return isValid;
	}

	const fetchFarmerDemandDetail = async () => {
		if (farmerDemandFilterValidation()) {
			let token = localStorage.getItem('Token');

			const listFilter = {
				FarmerCode: demandDistributionDetail.farmerCode,
				SowingMonth: demandDistributionDetail.sowingMonth,
				SowingYear: String(demandDistributionDetail.sowingYear)
			};

			setIsLoading(true);
			let response = await axios.post(
				process.env.REACT_APP_API_URL + '/get-farmer-demand-details',
				listFilter,
				{
					headers: { Authorization: `Bearer ${JSON.parse(token).value}` }
				}
			);

			if (response.data.status == 200) {
				setIsLoading(false);
				dispatch(demandDistributionProductAction(response.data.data));
				dispatch(demandDistributionProductDetailAction(undefined));
				// setOpenAccordions([]);
				// setProductNames(response.data.data);
			} else {
				toast.error('No demands found for the selected criteria!', {
					theme: 'colored',
					autoClose: 5000
				});
				dispatch(demandDistributionProductAction(undefined));
				dispatch(demandDistributionProductDetailAction(undefined));
				setIsLoading(false);
			}
		}
	};

	return (
		<>
			<Form>
				<Row>
					<Col md="4">
						<div style={{ position: 'relative' }}>
							<Form.Group
								as={Row}
								className="mb-1"
								controlId="formPlaintextPassword"
							>
								<Form.Label column sm="4">
									Farmer Name <span className="text-danger">*</span>
								</Form.Label>
								<Col sm="8">
									<Form.Control
										id="txtSearchFarmer"
										name="searchFarmer"
										placeholder="Search Farmer"
										maxLength={45}
										value={
											demandDistributionDetail.farmerName || showSearchFarmerValue
										}
										onChange={handleFarmerOnChange}
										onKeyDown={e => {
											if (e.key === 'Enter') {
												e.preventDefault();
											}
										}}
										autoComplete='off'
									/>
									{Object.keys(distributionFilterError.farmerCodeErr).map((key) => {
										return <span className="error-message">{distributionFilterError.farmerCodeErr[key]}</span>
									})}
								</Col>
							</Form.Group>
						</div>
					</Col>
					<Col md="3">
						<Form.Group
							as={Row}
							className="mb-1"
							controlId="sowingYear"
						>
							<Form.Label column sm="5">
								Sowing Year <span className="text-danger">*</span>
							</Form.Label>
							<Col sm="7">
								<Form.Select onChange={handleSowingYearChange} disabled={yearList.length == 1} value={demandDistributionDetail.sowingYear}>
									{yearList.length > 1 &&
										<option value=''>Select Year</option>
									}
									{yearList.map((year, index) => (
										<option key={index} value={year.value}>{year.key}</option>
									))}
								</Form.Select>
								{Object.keys(distributionFilterError.sowingYearErr).map((key) => {
										return <span className="error-message">{distributionFilterError.sowingYearErr[key]}</span>
									})}
							</Col>
						</Form.Group>
					</Col>
					<Col md="3">
						<Form.Group
							as={Row}
							className="mb-1"
							controlId="sowingMonth"
						>
							<Form.Label column sm="5">
								Sowing Month
							</Form.Label>
							<Col sm="7">
								<Form.Select onChange={handleSowingMonthChange} disabled={months.length == 1} value={demandDistributionDetail.sowingMonth}>
									{months.length > 1 &&
										<option value=''>Select Month</option>
									}
									{months.map((month, index) => (
										<option key={index} value={month.value}>{month.name}</option>
									))}
								</Form.Select>
							</Col>
						</Form.Group>
					</Col>
					<Col md="2">
						<Form.Group
							as={Row}
							className="mb-1"
							controlId="formPlaintextPassword"
						>
							<Col className='col-auto'>
								<Button variant="success" onClick={() => fetchFarmerDemandDetail()}>Filter</Button>
							</Col>
						</Form.Group>
					</Col>
				</Row>
				<Row>
					{/* <Col md="1"></Col> */}
					<Col md="10">
						<div style={{ position: 'relative' }}>
							<Form.Group
								as={Row}
								className="mb-1"
								controlId="formPlaintextPassword"
							>
								<Col sm="8">
									
									{showFarmerDropdown && farmerDetailsList.length > 0 && (
										<Card className="mb-1" 
										// style={{ position: 'absolute', top: '100%', left: 0, right: 0 }}
										>
											<Card.Body className="farmer-card-item">
												<div >
													<Table striped bordered responsive id="TableList" className="no-pb text-nowrap tab-page-table">
														<thead className='custom-bg-200'>
															{/* <thead> */}
															<tr>
																<th>Farmer Name</th>
																<th>Father's Name</th>
																<th>District</th>
																<th>State</th>
																<th>Village</th>
																<th>Phone Number</th>
															</tr>
														</thead>
														<tbody>
															{farmerDetailsList.map((farmer, index) => (
																<tr key={index}>
																	<td>
																		<Link
																			to=""
																			style={{ color: 'black' }}
																			onClick={e => {
																				e.preventDefault();
																				handleFarmerDetail(
																					farmer.farmerCode,
																					farmer.farmerName
																				);
																			}}
																		>
																			{farmer.farmerName}
																		</Link>
																	</td>
																	<td>{farmer.farmerFatherName}</td>
																	<td>{farmer.districtName}</td>
																	<td>{farmer.stateName}</td>
																	<td>{farmer.village}</td>
																	<td>{farmer.farmerPhoneNumber}</td>
																</tr>
															))}
														</tbody>
													</Table>
												</div>
											</Card.Body>
										</Card>
									)}
								</Col>
							</Form.Group>
						</div>
						</Col>
				</Row>
			</Form>
		</>
	);
}

export default AddDemandDistribute