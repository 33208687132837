import React, { useEffect, useState } from 'react';
import { Form, Card, Table } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import EnlargableTextbox from 'components/common/EnlargableTextbox';
import { demandDistributionProductDetailAction, demandDistributionProductAction, formChangedAction } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

const AddDemandDistributeProduct = () => {

	const demandDistributionProduct = useSelector((state) => state.rootReducer.demandDistributionProductReducer.demandDistributionProduct);
	const demandDistributionAddError = useSelector((state) => state.rootReducer.demandDistributionAddErrorReducer.demandDistributionAddError);

	const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
	var formChangedData = formChangedReducer.formChanged;

	useEffect(() => {
	}, [demandDistributionAddError]);
	
	const [isLoading, setIsLoading] = useState(false);
	const [selectedProductId, setSelectedProductId] = useState(null);
	const dispatch = useDispatch();

	const handleProductChange = (encryptedDemandDetailId) => {
		if (selectedProductId === encryptedDemandDetailId) {
			setSelectedProductId(null);
		} else {
			setSelectedProductId(encryptedDemandDetailId);
			getDemandDistributionDetail(encryptedDemandDetailId);
		}
	};
	
	const getDemandDistributionDetail = async (demandDetailId) => {
		let token = localStorage.getItem('Token');

		const listFilter = {
			encryptedDemandDetailId: demandDetailId
		};

		setIsLoading(true);
		let response = await axios.post(
			process.env.REACT_APP_API_URL + '/get-demand-distribution-list',
			listFilter,
			{
				headers: { Authorization: `Bearer ${JSON.parse(token).value}` }
			}
		);

		if (response.data.status == 200) {
			setIsLoading(false);
			dispatch(demandDistributionProductDetailAction(response.data.data));
		} else {
			setIsLoading(false);
			dispatch(demandDistributionProductDetailAction(undefined));
		}
	}

	const handleDistributingQtyChange = (e, index) => {
		demandDistributionProduct[index] = {
			...demandDistributionProduct[index],  // Copy the existing properties
			distributingQty : e.target.value    // Add the new key-value pair
		};

		dispatch(demandDistributionProductAction(demandDistributionProduct));
		if (e.target.value !== "") {
			dispatch(formChangedAction({
				...formChangedData,
				demandDistributionAdd: true
			}))
		}
	};


	return (
		<>
			{demandDistributionProduct && demandDistributionProduct.length > 0 &&
				<Card className="h-100 mb-2">
					<FalconCardHeader
						title="Demand Products"
						titleTag="h6"
						className="py-2"
						light
					/>
					<Card.Body className="position-relative pb-0 p3px">
						<Form
							noValidate

							className="details-form"
							id="AddDemandProductDetailsForm"
						>
							<Table
								striped
								bordered
								responsive
								id="TableList"
								className="no-pb text-nowrap tab-page-table"
							>
								<thead className="custom-bg-200">
									<tr>
										<th></th>
										<th>Name</th>
										<th>Brand</th>
										<th>Demand Qty</th>
										<th>Delivered Qty</th>
										<th>Delivering Qty</th>
									</tr>
								</thead>
								<tbody id="tbody" className="details-form">
									{demandDistributionProduct.map((demandProduct, index) => (
										<tr key={index}>
											<td>
												<input className="form-check-input" id={`flexRadioDefault${index}`} type="radio" name="flexRadioDefault"
													onChange={() => handleProductChange(demandProduct.encryptedDemandDetailId)}
													checked={demandProduct.encryptedDemandDetailId === selectedProductId} />
											</td>
											<td>{`${demandProduct.productName} (${demandProduct.productCategoryName})`}</td>
											<td>{demandProduct.brandName}</td>
											<td>{demandProduct.demandQty}</td>
											<td>{demandProduct.demandDistributedQty}</td>
											<td>
												<EnlargableTextbox
													name={`distributingQty${index}`}
													placeholder="Quantity"
													maxLength={5}
													required
													value = {demandProduct.distributingQty}
													onChange={(e) => handleDistributingQtyChange(e, index)}
												/>
												{demandDistributionAddError[index] && demandDistributionAddError[index]['distributingQtyErr'] && Object.keys(demandDistributionAddError[index].distributingQtyErr).map((key) => {
                    return <span className="error-message">{demandDistributionAddError[index].distributingQtyErr[key]}</span>
                  })}
											</td>
										</tr>
									))}

								</tbody>
							</Table>
						</Form>
					</Card.Body>
				</Card>
			}
		</>
	);
};

export default AddDemandDistributeProduct;
